import {
    TextField,
    Tooltip
} from "@material-ui/core";
import { Info } from "@material-ui/icons";

const TextFieldToolTip = ({ value, onValueChange, tooltipText, title, disabled = false, type = "text", hasFullWidth = false }) => {
    const handleDateChange = (event) => {
        onValueChange(event.target.value);
    };

    return (
        <TextField
            id={type}
            disabled={disabled}
            label={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {title}
                    <Tooltip title={tooltipText} arrow>
                        <Info fontSize="small" style={{ marginLeft: 8, cursor: 'pointer' }} />
                    </Tooltip>
                </span>
            }
            type={type}
            value={value}
            onChange={handleDateChange}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth={hasFullWidth}
        />
    );
};

export default TextFieldToolTip;

import {
  Checkbox,
  Chip,
  createTheme,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { MenuProps, useStyles } from "./useStyle";

/**
 * MultiSelect Component
 *
 * This component is a customizable multi-select dropdown with enhanced styling and tooltip functionality.
 * Built with Material-UI, it displays selected items as chips, allows multiple selections, and includes a
 * tooltip icon for displaying additional information. The component supports both controlled and uncontrolled
 * modes, making it versatile for various use cases.
 *
 * Props:
 * - `items`: An array of objects representing the dropdown options. Each object should have:
 *    - `value`: Unique identifier for each item.
 *    - `label`: Text to display in the dropdown.
 *
 * - `title`: A string displayed as the input's label.
 *
 * - `shouldWrap`: Boolean that controls whether the chips should wrap onto new lines when exceeding container width.
 *
 * - `defaultValue`: Array of default selected values, used for initial uncontrolled selection.
 *
 * - `value`: Array of selected values in controlled mode.
 *
 * - `onChange`: Callback function fired when the selection changes. Receives the array of selected values.
 *
 * - `width`: String controlling the width of the dropdown container.
 *
 * - `tooltipText`: Optional string for additional information displayed in a tooltip next to the label.
 *
 * - `chipHeight`: Controls the height of the chips used to display selected items.
 *
 * Key Features:
 * - **Tooltip for Label**: Displays additional information on hover over an info icon next to the label.
 * - **Customizable Chip Display**: Shows selected items as chips with customizable height and wrapping.
 * - **Material-UI Theme Provider**: Uses a custom Material-UI theme to style the primary color and label font size,
 *   adjusting dynamically if a tooltip is present.
 *
 * Usage Example:
 * ```jsx
 * <MultiSelect
 *   items={[{ value: 1, label: "Option 1" }, { value: 2, label: "Option 2" }]}
 *   title="Select Options"
 *   tooltipText="Choose multiple options"
 *   shouldWrap={true}
 *   defaultValue={[1]}
 *   onChange={(selected) => console.log(selected)}
 *   width="300px"
 * />
 * ```
 */
export default function MultiSelect({
  items = [],
  title = "",
  shouldWrap = false,
  defaultValue = [],
  value,
  onChange,
  width = "244px",
  tooltipText = "",
  chipHeight,
  disabled = false
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2", // Default primary color
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75em + ${tooltipText ? 3 : 0}px)`,
          },
        },
      },
    },
  });
  const classes = useStyles({ shouldWrap, width, chipHeight });
  const isControlled = value !== undefined;
  const [selectedItems, setSelectedItems] = useState(
    isControlled ? value : defaultValue.map((v) => v.value ?? v)
  );

  useEffect(() => {
    if (isControlled) {
      setSelectedItems(value.map((v) => v.value ?? v));
    }
  }, [value, isControlled]);

  const handleChange = useCallback(
    ({ target: { value: selectedValue } }) => {
      if (!isControlled) setSelectedItems(selectedValue);
      if (onChange) onChange(selectedValue);
    },
    [onChange]
  );

  const isChecked = useCallback(
    (checkValue) => selectedItems.includes(checkValue),
    [selectedItems]
  );

  const getChips = useCallback(
    (selected) => {
      const maxChipsToShow = 2;
      const chipsToShow = selected.slice(0, maxChipsToShow);
      const hasMoreChips = selected.length > maxChipsToShow;
      return (
        <div className={classes.chips}>
          {chipsToShow.map((v) => {
            const item = items.find((n) => n.value === v) || {
              value: v,
              label: v,
            };
            return (
              <Chip
                color="primary"
                key={item.value}
                label={item.label}
                className={classes.chip}
              />
            );
          })}
          {hasMoreChips && <Chip color="primary" label={`+${selected.length - maxChipsToShow} More`} className={classes.chip} />}
        </div>
      );
    },
    [selectedItems, items]
  );

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ marginTop: "5px", marginBottom: "5px" }}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id={`multi-select-${title}-label`}
            className={classes.inputLabel}
            disabled={disabled}
          >
            {title}
            {tooltipText && (
              <Tooltip title={tooltipText} arrow>
                <Info fontSize="small" className={classes.infoIcon} />
              </Tooltip>
            )}
          </InputLabel>
          <Select
            labelId={`multi-select-${title}-label`}
            id={`multi-select-${title}`}
            multiple
            value={selectedItems}
            onChange={handleChange}
            label={title}
            renderValue={getChips}
            MenuProps={MenuProps}
            disabled={disabled}
          >
            {items.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <ListItemText primary={label} />
                <Checkbox color="primary" checked={isChecked(value)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </ThemeProvider>
  );
}

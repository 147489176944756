import { Add as AddIcon } from "@material-ui/icons";
import { Fab, Paper, List, ListItem, ListItemText } from "@material-ui/core";
import { MentionsInput, Mention } from "react-mentions";
import React, { useRef, useState } from "react";

import classNames from "./textareaMentions.module.css";

const TextareaMentions = ({ chipOptions, onChange, value }) => {
  const [showList, setShowList] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Close dropdown when clicking outside
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowList(false);
    }
  };
  document.addEventListener("mousedown", handleOutsideClick);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <MentionsInput
        // Actual text area
        inputRef={inputRef}
        value={value}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          onChange(newValue);
        }}
        classNames={classNames}
        placeholder="Message"
      >
        <Mention
          // Component for the chips themselves
          trigger="#"
          data={chipOptions}
          className={classNames.mentions__mention}
          appendSpaceOnAdd={false}
          markup="{{__display__}}"
          displayTransform={(id, display) => `  ${display}  `}
        />
      </MentionsInput>
      {showList && (
        <Paper
          // Dropdown with chip options
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: "70px",
            left: "60px",
            right: "10px",
            maxHeight: "200px",
            maxWidth: "200px",
            overflowY: "auto",
            zIndex: 10,
          }}
        >
          <List>
            {chipOptions?.map((chipOption) => (
              <ListItem
                button
                key={chipOption.id}
                onClick={() => {
                  const cursorStart = inputRef.current.selectionStart;
                  // Replace the selected text with the chipOption.display or insert it
                  const newValue = value.slice(0, cursorStart) + `{{${chipOption.display}}}` + value.slice(cursorStart);
                  onChange(newValue);
                  setShowList(false);
                }}
              >
                <ListItemText primary={chipOption.display} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Fab
        // Floating action button to show chip options
        size="small"
        color="primary"
        style={{ position: "absolute", bottom: "10px", left: "10px" }}
        onClick={() => setShowList((prev) => !prev)}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default TextareaMentions;

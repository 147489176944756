import Config from './config';
import { compiler } from 'markdown-to-jsx';
import {
    getVehicleModelNames,
    getVehicleS3PathName,
    uploadFileWithPresignedUrl,
    requestPresignedUrlForFile
} from "./services/mcApiService";

export function getEnvironments() {
    return Config.environments;
}

export function isProductionEnvironment() {
    return Config.isProd;
}
export function isValidFileName(filename) {
    const valid = /^[\w-.]+$/;
    if (filename.match(valid)) {
        return true;
    }
    return false;
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            return resolve(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            return reject(error);
        };
    });
}

export function getS3ImagePathApp(modelName, modelYear, templateType) {
    switch (templateType) {
        case "Vehicle":
            switch (getEnvironments().destinationEnvironment) {
                case "staging":
                case "prod":
                    return `staging/content-files/images/product_images/${modelYear}_${modelName.toLowerCase().replace(' ', '_')}/`;
                case "local":
                case "dev":
                default:
                    return `dev/content-files/images/product_images/${modelYear}_${modelName.toLowerCase().replace(' ', '_')}/`;
            }
        case "App Walkthrough (App)":
            switch (getEnvironments().destinationEnvironment) {
                case "staging":
                case "prod":
                    return `staging/content-files/images/appWalkthrough/`;
                case "local":
                case "dev":
                default:
                    return `dev/content-files/images/appWalkthrough/`;
            }
    }
}

export async function getFormattedModelName(modelName) {
    if (!modelName) return '';
    const vehicleS3PathName = await getVehicleS3PathName(modelName);
    return vehicleS3PathName;
}

export function getUrlModelName(modelName) {
    if (!modelName) {
        return null;
    }

    switch (modelName.toLowerCase()) {
        case "c-hr":
            modelName = "CHR";
            break;
        case "prius c": // NOTE that for new vehicles we will need to also update subheader.component.js:24-45
        case "prius *c*":
            modelName = "PRIUSC";
            break;
        case "prius v":
        case "prius *v*":
            modelName = "PRIUSV";
            break;
        case "land cruiser":
            modelName = "LAND";
            break;
        case "prius prime":
            modelName = "PRIME";
            break;
        case "corolla im":
            modelName = "COROLLAIM";
            break;
        case "yaris ia":
            modelName = "YARISIA";
            break;
        case "corolla cross":
            modelName = "COROLLACROSS";
            break;
        case "corolla hatchback":
            modelName = "COROLLAHATCHBACK";
            break;
        case "yaris sedan":
            modelName = "YARISSEDAN";
            break;
        case "prius plug-in":
            modelName = "PLUGIN";
            break;
        case "fj cruiser":
            modelName = "FJ";
            break;
        case "rav4 ev":
            modelName = "RAV4EV";
            break;
        case "gr supra":
            modelName = "SUPRA";
            break;
    }
    return modelName.toUpperCase();
}

export async function getS3ImagePath(vehicleModel, vehicleYear, templateType) {
    let path = "static/images/";
    let model;

    if (["Vehicle", "Model Overview (MY21+)", "Competitive Advantages", "Edge (Legacy)", "Head to Head", "Head to Head v2"].includes(templateType)) {
        model = vehicleModel ? await getFormattedModelName(vehicleModel) : '';
    }

    switch (templateType) {
        case "Vehicle":
        case "Model Overview (MY21+)":
            path += "vehicle/" + vehicleYear + "/" + model + "/en/";
            break;
        case "Competitive Advantages":
            path += "competitive_advantages_overview/" + vehicleYear + "/" + model + "/en/";
            break;
        case "Edge (Legacy)":
        case "Head to Head":
        case "Head to Head v2":
            path += "edge/" + vehicleYear + "/" + model + "/en/";
            break;
        case "App Page":
            path += "appLanding/en/";
            break;
        case "Audio Multimedia":
            path += "amm/";
            break;
        case "Beyond Zero":
            path += "beyondZero/";
            break;
        case "Certification":
            path += "certification/en/";
            break;
        case "EngageXP":
            path += "engageXP/";
            break;
        case "Feature Lookup":
            path += "featureLookup/";
            break;
        case "Homepage v2":
            path += "homepage_v2/";
            break;
        case "Homepage v3":
            path += "homepage_v3/";
            break;
        case "Login":
            path += "login/";
            break;
        case "Nitro Overview":
            path += "nitroOverview/";
            break;
        case "Quick Start Guide":
            path += "quickStartGuide/";
            break;
        case "Recalls and Safety Campaign":
            path += "recalls/";
            break;
        case "Safety Connect":
            path += "safety_connect/en/";
            break;
        case "Safety Landing":
            path += "safetyLanding/en/";
            break;
        case "Service Connect":
            path += "serviceConnect/";
            break;
        case "SmartPath":
            path += "smartpath/en/";
            break;
        case 'Star Safety System':
            path += 'starSafetySystem/en/';
            break;
        case "TCUV":
            path += "tcuv/en/";
            break;
        case "Tournament":
            path += "engageXP/tournaments/";
            break;
        case "TSS":
            path += "toyota_safety_sense/";
            break;
        case "Towing & Payload":
            path += "towing_payload_101/";
            break;
        default:
            break;
    }
    return path;
}

export function getTemplatePath(templateType) {
    let path = "";

    switch (templateType) {
        case "App Page":
            path += "appLanding/";
            break;
        case "Audio Multimedia":
            path += "amm/";
            break;
        case "App Walkthrough (App)":
            path += "appWalkthrough/";
            break;
        case "Beyond Zero":
            path += "beyondZero/";
            break;
        case "Certification":
            path += "certification/";
            break;
        case "Competitive Advantages":
            path += "ca_overview/";
            break;
        case "Edge (Legacy)":
            path += "edge/";
            break;
        case "EngageXP":
            path += "engageXP/";
            break;
        case "Feature Lookup":
            path += "featureLookup/";
            break;
        case "Head to Head":
            path += "headToHead/";
            break;
        case "Head to Head v2":
            path += "headToHead_v2/";
            break;
        case "Homepage v2":
            path += "homepage_v2/";
            break;
        case "Homepage v3":
            path += "homepage_v3/";
            break;
        case "Login":
            path += "login/";
            break;
        case "Model Overview (MY21+)":
            path += "modelOverview/";
            break;
        case "Nitro Overview":
            path += "nitroOverview/";
            break;
        case "Quick Start Guide":
            path += "quickStartGuide/";
            break;
        case "Recalls and Safety Campaign":
            path += "recalls/";
            break;
        case "Safety Connect":
            path += "safety_connect/";
            break;
        case "Safety Landing":
            path += "safetyLanding/";
            break;
        case "Service Connect":
            path += "serviceConnect/";
            break;
        case "SmartPath":
            path += "smartpath/";
            break;
        case 'Star Safety System':
            path += 'starSafetySystem/';
            break;
        case "TCUV":
            path += "tcuv/";
            break;
        case "Tournament":
            path += "tournament/";
            break;
        case "TSS":
            path += "toyotaSafetySense/";
            break;
        case "Vehicle":
            path += "vehicles/";
            break;
        case 'Towing & Payload':
            path += 'towingPayload/';
            break;
        default:
            break;
    }
    if (templateType !== 'App Walkthrough (App)') {
        path += "en/";
    }
    return path;
}

export function getTemplateTypes() {
    return [
        "App Page",
        "Audio Multimedia",
        "App Walkthrough (App)",
        "Beyond Zero",
        "Certification",
        "Competitive Advantages",
        "Edge (Legacy)",
        "EngageXP",
        "Feature Lookup",
        "Head to Head",
        "Head to Head v2",
        "Homepage v2",
        "Homepage v3",
        "Login",
        "Model Overview (MY21+)",
        "Nitro Overview",
        "Quick Start Guide",
        "Recalls and Safety Campaign",
        "Safety Landing",
        "Safety Connect",
        "Service Connect",
        "SmartPath",
        "Star Safety System",
        "Tournament",
        "TCUV",
        "TSS",
        "Vehicle",
        "Towing & Payload",
    ];
}

export function getAppTemplateTypes() {
    return [
        "App Walkthrough",
        // "Vehicle"
    ];
}

export async function getVehicleModels() {
    const vehicleModels = await getVehicleModelNames();
    const ordenedVehicleModels = vehicleModels.sort();
    return ordenedVehicleModels;
}

// Return an array of years from 2018 to the year following the current year in descending order
export function getVehicleYears() {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;
    const years = [];
    for (let year = currentYear + 1; year >= startYear; year--) {
        years.push(year.toString());
    }
    return years;
}

export function isOneToMany(vehicleModel) {
    const oneToManyVehicles = ["Tundra", "Tacoma", "Sequoia", "4Runner"];
    return oneToManyVehicles.includes(vehicleModel) ? true : false;
}

export function getTemplateVersion(templateName, year) {
    let templateType = templateName;
    if (templateType === "Head to Head" && year >= 2021) {
        templateType = "Head to Head v2";
    };
    return templateType;
}

export function getTarget(href) {
    if (href) {
        const isLinkToPdf = href.endsWith('.pdf');
        const isInternalLink = href.startsWith('/') || href.includes('engage.toyota.com');
        if (isLinkToPdf || !isInternalLink) {
            return '_blank';
        }
    }
    return '_self';
}

export function applyTrademarkSymbols(text) {
    text = text.replace(/\^\(?SM\)?\^/g, '℠');
    text = text.replace(/\^\(?TM\)?\^/g, '™');
    text = text.replace(/\^\(?R\)?\^/g, '®');
    text = text.replace(/\^\(?C\)?\^/g, '©');
    return text;
}

export function applyMarkdown(text, replaceLinebreak = false) {
    if (text) {
        if (replaceLinebreak) {
            text = text// line break
                .replace(/\\n/gm, "\n")
                .replace(/\\r/gm, "\n");
        }

        text = text.replace(/\^(\d+)\^/g, '<sup>$1</sup>');
        text = text.replace(/\^\\(\*\d+)\^/g, '<sup>$1</sup>');
        text = text.replace(/\^\(?SM\)?\^/g, '℠');
        text = text.replace(/\^\(?TM\)?\^/g, '™');
        text = text.replace(/\^\(?R\)?\^/g, '®');
        const textJSX = compiler(text);
        if (textJSX.type === 'ul') {
            return textJSX;
        }
        return <span>{textJSX.props.children}</span>;
    }
    return null;
}

export function getImageUrl(image) {
    if (image.includes('https://') || image.includes('http://')) {
        return image;
    }
    const environment = Config.environments.sourceEnvironment;
    let cloudFrontUrl;
    switch (environment) {
        case 'staging':
            cloudFrontUrl = 'https://staging.engage.toyota.com';
            break;
        case 'dev':
            cloudFrontUrl = 'https://dev.nonprod.engage.toyota.com';
            break;
    }

    return `${cloudFrontUrl}/static/images/announcements/${image}`;
}

export async function uploadNotificationImage(image) {
    return new Promise(
        async (resolve, reject) => {
            let requestBody;
            requestBody = {
                key: "static/images/notifications/" + image.name,
                bucket: gets3Bucket(false),
            };

            requestPresignedUrlForFile(requestBody)
                .then(function (response) {
                    const signedUrl = response.data;
                    uploadFileWithPresignedUrl(signedUrl, image.name, image)
                        .then(function (response) {
                            return resolve(response);
                        });
                })
                .catch(function (err) {
                    console.error("Error uploading image " + image.name + " : " + err);
                    reject(err);
                });
            return resolve();
        });
}

export function removeMarkdown(text, removeLineBreaks = false) {
    if (!removeLineBreaks) {
        text = text // line break
            .replace(/\\n/gm, "\n")
            .replace(/\\r/gm, "\n");
    } else {
        text = text // line break
            .replace(/\\n/gm, "")
            .replace(/\\r/gm, "");
    }

    return text
        // multiple spaces
        .replace(/ +(?= )/g, '')
        // Strikethrough
        .replace(/~~/g, '')
        // Fenced codeblocks
        .replace(/`{3}.*\n/g, '')
        .replace(/<(.*?)>/g, '$1')
        // Remove setext-style headers
        .replace(/^[=\-]{2,}\s*$/g, '')
        // Remove footnotes?
        .replace(/\[\^.+?\](\: .*?$)?/g, '')
        .replace(/\s{0,2}\[.*?\]: .*?$/g, '')
        // Remove images
        .replace(/\!\[.*?\][\[\(].*?[\]\)]/g, '')
        // Remove inline links
        .replace(/\[(.*?)\]\((.+?)\)/g, '$1 $2')
        // Remove Blockquotes
        .replace(/>/g, '')
        // Remove reference-style links?
        .replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
        // Remove atx-style headers
        .replace(/^\#{1,6}\s*([^#]*)\s*(\#{1,6})?/gm, '$1')
        .replace(/([\*_]{1,3})(\S.*?\S)\1/g, '$2')
        .replace(/(`{3,})(.*?)\1/gm, '$2')
        .replace(/^-{3,}\s*$/g, '')
        .replace(/`(.+?)`/g, '$1')
        .replace(/\n{2,}/g, '\n\n');
}

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};
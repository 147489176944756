import React, { Component } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editorConfig } from './ckEditorConfig';
import { ClassicEditor } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import CreateIcon from '@material-ui/icons/Create';
import { getTemplateValue } from './textEditorTemplates';

export default class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      embeddedVideoIndex: 0,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCKEditorChange = this.handleCKEditorChange.bind(this)
    this.imageHandler = this.imageHandler.bind(this)
    this.getToolbarOptions = this.getToolbarOptions.bind(this);
    this.qumuEmbedHandler = this.qumuEmbedHandler.bind(this);
    this.createCKEditor = this.createCKEditor.bind(this);
    this.editorRef = React.createRef();
    this.editorInstance = null;
    this.attachments = [];
    this.previousAttachments = [];
    this.textEditorInit = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const thirdPageHeight = document.documentElement.scrollHeight / 3;
    const appBar = document.getElementById('AppBar');
    appBar && (scrollPosition > thirdPageHeight ? appBar.classList.add('hidden') : appBar.classList.remove('hidden')); 
  };

  async getPreviousFileNames(files) {
    const newFiles = files.map(path => {
      const splitPath = path.split('/');
      const filename = splitPath[splitPath.length - 1];
      return { name: filename };
    });
    this.previousAttachments = newFiles;
  }

  async componentDidUpdate(prevProps, prevState) {

    if (!this.props.previouslyUploadedFiles && !this.textEditorInit) {
      this.createCKEditor();
      this.textEditorInit = true;
    }

    if (this.props.previouslyUploadedFiles !== prevProps.previouslyUploadedFiles ||
      this.props.articleFiles !== prevProps.articleFiles || 
      this.props.hasTemplate !== prevProps.hasTemplate) {
      // Handle previous file names when previouslyUploadedFiles changes
      if (this.props.previouslyUploadedFiles !== prevProps.previouslyUploadedFiles) {
        await this.getPreviousFileNames(this.props.previouslyUploadedFiles);
      }

      this.attachments = [
        ...(Array.isArray(this.props.articleFiles) ? this.props.articleFiles : []),
        ...(Array.isArray(this.previousAttachments) ? this.previousAttachments : [])
      ];

      this.setState(
        prevState => ({ text: this.props.text || prevState.text } )
      , async () => {
        // Destroy the existing editor instance before creating a new one
        if (this.editorInstance) {
          try {
            await this.editorInstance.destroy();
          } catch (error) {
            console.error('Error destroying CKEditor:', error);
          }
        }
        // Create a new editor instance after destruction (or if no editor exists)
        await this.createCKEditor();
      });
    }
  }

  handleCKEditorChange(editor) {
    const text = editor.data.get();
    this.setState({ text })
    this.props.contentUpdate(text);
  }

  handleChange(value) {
    this.setState({ text: value })
    this.props.contentUpdate(value);
  }

  async imageHandler(image, callback) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const addFileSuccess = await this.props.addFile(file);
      if (addFileSuccess) {
        const range = await this.quillRef.getEditor().getSelection();
        const bucketEnv = window.location.hostname.includes('test') || window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? "dev.nonprod" : "staging";
        const imageUrl = `https://${bucketEnv}.engage.toyota.com/static/articles/embeddedImages/${file.name}`;
        this.quillRef.getEditor().insertEmbed(range.index, 'image', imageUrl, "user");
      }
    };
  }

  async qumuEmbedHandler() {
    const qumuInput = prompt('Please enter a Qumu ID');

    if (qumuInput) {
      const inputRange = await this.quillRef.getEditor().getSelection(qumuInput);
      const videoLink = `https://video-toyota.qumucloud.com/view/${qumuInput}`;
      const req = new XMLHttpRequest();
      this.quillRef.getEditor().insertEmbed(inputRange.index, 'video', videoLink, 'user');
    }
    return null;
  }

  getToolbarOptions() {
    const options = [
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      ['qumu', 'video', 'image', 'link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
    ];
    return options;
  }

  async createCKEditor() {
    const { text } = this.state;
    const editorRef = this.editorRef.current;
    await ClassicEditor
      .create(editorRef, editorConfig(this.attachments))
      .then(editor => {
        this.editorInstance = editor;
        text && this.editorInstance.setData(text);
        if (this.props.hasTemplate) {
          this.editorInstance.setData(getTemplateValue(this.props.templateValue));
          this.handleCKEditorChange(this.editorInstance);
          this.props.updateHasTemplate(false);
        }
        this.editorInstance.model.document.on('change:data', () => {
          this.handleCKEditorChange(this.editorInstance);
        });
      })
      .catch(error => {
        console.error('Error initializing CKEditor:', error);
      });
  }
    
  render() {
    const ckEditor = document.getElementsByClassName('ck-editor')[0];
    if (ckEditor) {
      ckEditor.style.display = this.props.isCKEditor ? 'block' : 'none';
    }
    return (
      <div className="Text-Editor">
        <div className='Text-Editor-Header'>
          <CreateIcon /> Create Article Content:
        </div>
        <div style={{ position: 'relative', width: '100%', display: 'block' }} id="editor-container">
          <div ref={this.editorRef} id="editor" />
        </div>
        <ReactQuill
          style={{
            display: this.props.isCKEditor ? 'none' : 'block',
            width: this.props.isCKEditor ? 'none' : '90%'
          }}
          modules={{
            toolbar: {
              container: this.getToolbarOptions(),
              handlers: {
                'image': this.imageHandler,
                'qumu': this.qumuEmbedHandler,
              }
            },
          }}
          ref={(el) => this.quillRef = el}
          value={this.state.text}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
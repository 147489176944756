import React, { useState } from 'react';
import config from '../../config';
import DialogV2 from '../../components/DialogV2';
import { archiveNotification, deleteScheduledPromotion } from '../../services/mcApiService';
import {
  format,
} from 'date-fns'

// MATERIAL-UI COMPONENTS
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  Alert
} from '@material-ui/lab';

function ArchiveDialog(props) {
  const [loading, setLoading] = useState(false);

  const category = props.notification.category == "Outage Alert" ? "Outage Alert" : "Announcement";

  function archive() {
    setLoading(true);
    archiveNotification(props.notification?.id)
      .then(() => {
        if(props.notification.isScheduled) {
          deleteScheduledPromotion(props.notification.id).then(() => {
            setLoading(false);
              props.closeDialog(true, "Notification archived successfully!");
          })
        } else {
          setLoading(false);
          props.closeDialog(true, "Notification archived successfully!");
        }
      })
      .catch((err) => {
        setLoading(false);
        props.closeDialog(false, "Notification failed to archive.");
      })
  }

  const renderContent = () => {
    if (props.notification?.isPush && props.notification?.isPromoted) {
      return (
        <Alert severity="info">
          Push notifications cannot be "unsent", but archiving will remove the <strong>{category}</strong> version of this notification from {config.environments.destinationEnvironment}.
        </Alert>
      );
    } else if (props.notification?.isScheduled) {
      return (
        <Alert severity="error">
          This {category} is scheduled to be promoted on <strong>{format(new Date(props.notification.scheduledDate), "PPPPp")}</strong>. Would you like to archive anyway?.
        </Alert>
      );
    } else {
      return null;
    }
  }

  const getTitle = () => {
    if (props.notification.isPromoted) {
      return `Remove this ${category} from ${config.environments.sourceEnvironment} and ${config.environments.destinationEnvironment}?`
    } else {
      return `Remove this ${category} from ${config.environments.sourceEnvironment}?`
    }
  }

  const renderActions = () => {
    return (<>
      <Button
        onClick={props.closeDialog}
        disabled={loading}
        color="primary"
        variant="text"
      >Cancel</Button>

      <Button
        onClick={archive}
        startIcon={loading ? <CircularProgress size="20px" color="inherit" /> : undefined}
        disabled={loading}
        style={{ color: '#FFFFFF', backgroundColor: '#EB0A1E' }}
        variant="contained"
        autoFocus
      >Archive</Button>
    </>);
  }

  return (
    <DialogV2
      open={props.open}
      onClose={props.closeDialog}
      title={getTitle()}
      content={renderContent()}
      actions={renderActions()}
    />
  )
}

export default ArchiveDialog;

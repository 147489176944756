import React, { useEffect, useState } from "react";
import { InsertPhoto, Add } from '@material-ui/icons';
import { Button, TextField, Tooltip } from '@material-ui/core';
import FileUploader from "../../components/FileUploader";

const AddVehicleItem = ( {addFunction, validateFunction} ) => {
    const [year, setYear] = useState('');
    const [model, setModel] = useState('');
    const [href, setHref] = useState('');
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});

    const addImage = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        setImage(file);
    };

    const handleAdd = () => {
        const errors = validateFunction(year, model, href);

        if (Object.keys(errors).length) {
            setErrors(errors);
        } else {
            const vehicleItem = {
                modelYear: year,
                model: model,
                href: href,
                rotatedImgSrc: 'profile/upcoming/defaultComingSoonImage.png',
            }

            if (image !== null) {
                vehicleItem.image = image;
                vehicleItem.rotatedImgSrc = `profile/upcoming/${Date.now()}-${image.name}`;
            }

            addFunction(vehicleItem);
    
            // Clear fields
            setYear('');
            setModel('');
            setHref('');
            setImage(null);
            setErrors({});
        }
    };

    return (
      <div className="add-item-section">
        <TextField
            label={'Year'}
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder={`Ex. ${new Date().getFullYear() + 1}`}
            variant="outlined"
            size="small"
            className="input-field year"
            error={errors['year']}
            helperText={errors['year'] || ''}
        />
        <TextField
            label={'Model'}
            value={model}
            onChange={(e) => setModel(e.target.value)}
            placeholder={`Ex. Camry`}
            variant="outlined"
            size="small"
            className="input-field model"
            error={errors['model']}
            helperText={errors['model'] || ''}
        />
        <TextField
            label={'URL'}
            value={href}
            onChange={(e) => setHref(e.target.value)}
            placeholder={`Ex. /articles/camry_overview`}
            variant="outlined"
            size="small"
            className="input-field url"
            error={errors['href']}
            helperText={errors['href'] || ''}
        />
        <Tooltip title={'Upload Image'}>
            <div className='file-uploader-wrapper'>
                <FileUploader title={<InsertPhoto />} addFile={(e) => addImage(e)} />
            </div>
        </Tooltip>
        <Button onClick={() => handleAdd()} variant="contained" className="add-item-button" startIcon={<Add />}>Add</Button>
      </div>
    );
};

export default AddVehicleItem;
import React, { useState, useEffect } from 'react';
import '../../App.css';
import {
    addDays,
    addMinutes,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    format,
    roundToNearestMinutes,
    compareAsc
} from 'date-fns';
import DialogV2 from '../../components/DialogV2';
import DateTimePickerDialog from '../../components/DateTimePickerDialog';
import { getEnvironments } from '../../utils';
import {
    deleteScheduledPromotion,
    getNotificationFilters,
    promoteNotification,
    queryNumRecipients,
    scheduleNotification,
} from '../../services/mcApiService';

// MATERIAL-UI COMPONENTS
import {
    Button,
    Grid,
    CircularProgress,
    Badge,
    Card
} from '@material-ui/core';

import {
    Alert
} from '@material-ui/lab';

import {
    Person as PersonIcon,
    Warning as WarningIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

export default function PromoteDialog(props) {
    const [promoteMethod, setPromoteMethod] = useState();
    const [now, setNow] = useState();
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasCustomRecipients, setHasCustomRecipients] = useState(false);
    const [numberRecipients, setNumberRecipients] = useState(0);
    const category = props.notification.category == "Outage Alert" ? "Outage Alert" : "Announcement";
    const timeZone = 'CST';

    useEffect(() => {
        // Get number of recipients from a given notification ID
        const getNumRecipients = async (notificationId) => {
            try {
                const { data: queriedFilters } = await getNotificationFilters(notificationId);
                const { data } = await queryNumRecipients(queriedFilters);
                setNumberRecipients(data?.user_count);
            } catch (err) {
                alert(`Error getting number of recipients: ${JSON.stringify(err)}`);
            }
        };

        props.notification.isScheduled && setPromoteMethod("Promote Scheduled");
        getNumRecipients(props.notification?.id);
        setHasCustomRecipients(true);
    }, []);

    useEffect(() => {
        const currentDateTimeRounded = roundToNearestMinutes(now, { nearestTo: 30, roundingMethod: "ceil" });
        if (promoteMethod == "Promote") {
            if (category == "Outage Alert") {
                setStartDateTime(now);
                setEndDateTime(addDays(currentDateTimeRounded, 1));
            } else {
                setStartDateTime(now);
                setEndDateTime(addDays(currentDateTimeRounded, 30));
            }
        } else if (promoteMethod == "Schedule") {
            if (category == "Outage Alert") {
                setStartDateTime(addDays(currentDateTimeRounded, 1));
                setEndDateTime(addDays(currentDateTimeRounded, 2));
            } else {
                setStartDateTime(addDays(currentDateTimeRounded, 1));
            }
        } else if (promoteMethod == "Promote Scheduled") {
            setStartDateTime(new Date(props.notification.scheduledDate));
            setEndDateTime(new Date(props.notification.expirationDate));
        } else if (promoteMethod == null) {
            setNow(new Date());
            setStartDateTime(new Date());
            setEndDateTime(new Date());
        }
    }, [promoteMethod]);

    useEffect(() => {
        // if scheduling a non-outage announcement, adjust end date to 30 days after selected start/schedule date
        if (promoteMethod == "Schedule" && category != "Outage Alert") {
            setEndDateTime(addDays(startDateTime, 30));
        }
    }, [startDateTime]);

    useEffect(() => {
        // compare dates for invalid values
        promoteMethod && setDisable((compareAsc(endDateTime, startDateTime) != 1) || (compareAsc(startDateTime, now) == -1));
    }, [startDateTime, endDateTime]);


    function promote() {
        setLoading(true);
        promoteNotification(props.notification?.id, endDateTime?.toISOString())
            .then(() => {
                if (props.notification.isScheduled) {
                    deleteScheduledPromotion(props.notification.id).then(() => {
                        setLoading(false);
                        props.closeDialog(true, "Notification promoted successfully!");
                    });
                } else {
                    setLoading(false);
                    props.closeDialog(true, "Notification promoted successfully!");
                }
            })
            .catch((err) => {
                setLoading(false);
                props.closeDialog(false, "Notification failed to promote.");
            });
    }

    function schedule() {
        setLoading(true);
        const scheduleNotificationData = {
            id: props.notification.id,
            scheduledDate: startDateTime.toISOString(),
            expirationDate: endDateTime.toISOString()
        };
        scheduleNotification(scheduleNotificationData)
            .then(() => {
                setLoading(false);
                props.closeDialog(true, "Notification scheduled successfully!");
            })
            .catch((err) => {
                setLoading(false);
                props.closeDialog(false, "Notification failed to schedule.");
            });
    }

    const getTitle = () => {
        if (promoteMethod) {
            return `${promoteMethod} ${category}`;
        } else {
            return (
                <div className='notif-promote-content-title'>
                    <WarningIcon />
                    {`Are you sure you want to send this ${category.toLowerCase()} to ${getEnvironments().destinationEnvironment}${props.notification.isPush ? " & push notification?" : "?"}`}
                </div>
            );
        }
    };

    const getDateTimePickers = () => {
        if (category == "Outage Alert") {
            if (promoteMethod == "Promote") {
                return <DateTimePickerDialog label="Expiration Date Time" date={endDateTime} setDate={setEndDateTime} minDate={addMinutes(startDateTime, 1)} />;
            } else if (promoteMethod == "Schedule") {
                return <>
                    <DateTimePickerDialog label="Scheduled Date Time" date={startDateTime} setDate={setStartDateTime} minDate={now} />
                    <DateTimePickerDialog label="Expiration Date Time" date={endDateTime} setDate={setEndDateTime} minDate={addMinutes(startDateTime, 1)} />
                </>;
            }
        } else if (category != "Outage Alert" && promoteMethod == "Schedule") {
            return <DateTimePickerDialog label="Scheduled Date Time" date={startDateTime} setDate={setStartDateTime} minDate={now} />;
        }
    };

    const getTimeDifference = () => {
        let days = differenceInDays(endDateTime, startDateTime);
        let hours = differenceInHours(endDateTime, startDateTime);
        let minutes = differenceInMinutes(endDateTime, startDateTime);

        if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""}`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? "s" : ""}`;
        } else {
            return `${minutes} minute${minutes > 1 ? "s" : ""}`;
        }
    };

    const LiveOnEngageAlert = (props) => (
        <Alert severity="info">
            This {category} will be live on Engage for <strong>{getTimeDifference()}</strong> from <strong>{props.start}</strong> until <strong>{props.end}</strong>.
        </Alert>
    );

    const Default30DaysAlert = () => (
        <Alert severity="info" variant="outlined" >
            Announcements are live on Engage for 30 days by default. This time may be adjusted by clicking the Expiration date after promoting or scheduling.
        </Alert>
    );
    const RecipientsAlert = () => (
        <Alert severity="warning">
            You have modified the Recipients. Please ensure your configuration is correct.
        </Alert>
    );

    const PushNotificationAlert = (props) => (
        <Alert severity="info">
            This {category} will be sent as an Engage App push notification {props.start != "now" && "on "}<strong>{props.start}</strong>.
        </Alert>
    );

    const DatesErrorAlert = () => (
        <Alert severity="error">
            The chosen values are invalid. Please select future Date Times to proceed.
        </Alert>
    );

    const PromoteScheduledAlert = (props) => (
        <Alert severity="error">
            This {category} is scheduled to be promoted on <strong>{props.start}</strong>. Would you like to promote <strong>now</strong> anyway?
        </Alert>
    );

    const getAlertMessages = () => {
        const alerts = [];
        if (disable) {
            return <DatesErrorAlert />;
        }
        if (promoteMethod == "Promote") {
            alerts.push(<LiveOnEngageAlert start="now" end={`${format(endDateTime, "PPPPp")} ${timeZone}`} />);
            props.notification.isPush && alerts.push(<PushNotificationAlert start="now" />);
            category == "Announcement" && alerts.push(<Default30DaysAlert />);
            hasCustomRecipients && alerts.push(<RecipientsAlert />);
        } else if (promoteMethod == "Schedule") {
            alerts.push(<LiveOnEngageAlert start={`${format(startDateTime, "PPPPp")} ${timeZone}`} end={`${format(endDateTime, "PPPPp")} ${timeZone}`} />);
            props.notification.isPush && alerts.push(<PushNotificationAlert start={`${format(startDateTime, "PPPPp")} ${timeZone}`} />);
            category == "Announcement" && alerts.push(<Default30DaysAlert />);
            hasCustomRecipients && alerts.push(<RecipientsAlert />);
        } else if (promoteMethod == "Promote Scheduled") {
            alerts.push(<LiveOnEngageAlert start="now" end={`${format(endDateTime, "PPPPp")} ${timeZone}`} />);
            props.notification.isPush && alerts.push(<PushNotificationAlert start="now" />);
            alerts.push(<PromoteScheduledAlert start={`${format(startDateTime, "PPPPp")} ${timeZone}`} />);
        }

        return alerts;
    };

    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: -16,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '5px',
        },
    }))(Badge);

    const getContent = () => (
        <>
            {!promoteMethod &&
                <Card>
                    <div className='notif-promote-content-body'>
                        <span style={hasCustomRecipients ? { color: 'red' } : {}}>
                            {hasCustomRecipients ? 'You have modified the Recipients. Please ensure your configuration is correct. ' : 'This notification is configured to send to all users. '}
                        </span>
                    </div>

                    <span className='notif-user-count'> Number of users notified:
                        <StyledBadge color="secondary" badgeContent={numberRecipients} showZero max={10000}>
                            <PersonIcon />
                        </StyledBadge>
                    </span>

                </Card>
            }

            {promoteMethod &&
                < Grid container direction="column" style={{ gap: "20px" }}>
                    {(promoteMethod == "Schedule" || category == "Outage Alert") && <Grid container direction="row" justifyContent="space-evenly">
                        {getDateTimePickers()}
                    </Grid>}
                    <Grid container direction="column" justifyContent="space-evenly" style={{ gap: "10px" }}>
                        {getAlertMessages()}
                    </Grid>
                </Grid>
            }
        </>
    );

    const getButtons = () => {
        const PromoteButton = (props) =>
            <Button
                onClick={() => props.onClick()}
                style={!props.disabled ? { backgroundColor: "#7D4285", color: "white", fontSize: '13px' } : { fontSize: '13px' }}
                variant="contained"
                disabled={props.disabled || loading}
                startIcon={loading ? <CircularProgress size="20px" color="inherit" /> : undefined}
            >Promote Now</Button>;

        const ScheduleButton = (props) =>
            <Button
                onClick={() => props.onClick()}
                color="secondary"
                variant="contained"
                disabled={props.disabled || loading}
                startIcon={loading ? <CircularProgress size="20px" color="inherit" /> : undefined}
                style={{ fontSize: '13px' }}
            >Schedule Promotion</Button>;

        const BackButton = () => <Button onClick={() => setPromoteMethod(null)} color="primary" variant="text" disabled={loading} style={{ fontSize: '13px' }}>Back</Button>;
        const CancelButton = () => <Button onClick={() => props.closeDialog()} color="primary" variant="text" disabled={loading} style={{ fontSize: '13px' }}>Cancel</Button>;

        const actionButtons = [];
        if (promoteMethod == "Promote") {
            actionButtons.push(<BackButton />);
            actionButtons.push(<PromoteButton onClick={() => promote()} disabled={disable} />);
        } else if (promoteMethod == "Schedule") {
            actionButtons.push(<BackButton />);
            actionButtons.push(<ScheduleButton onClick={() => schedule()} disabled={disable} />);
        } else if (promoteMethod == "Promote Scheduled") {
            actionButtons.push(<CancelButton />);
            actionButtons.push(<PromoteButton onClick={() => promote()} disabled={disable} />);
        } else {
            actionButtons.push(<CancelButton />);
            actionButtons.push(<PromoteButton onClick={() => setPromoteMethod("Promote")} />);
            actionButtons.push(<ScheduleButton onClick={() => setPromoteMethod("Schedule")} />);
        }

        return <>
            {actionButtons}
        </>;
    };

    return (
        <DialogV2
            open={props.open}
            title={getTitle()}
            content={getContent()}
            actions={getButtons()}
        />
    );
}

import React, { useEffect, useState } from 'react';
import { List, Button, CircularProgress, Snackbar } from '@material-ui/core';
import './upcomingVehiclesMenu.css';
import { getBase64 } from '../../utils';
import AddVehicleItem from './addVehicleItem';
import VehicleListItem from './vehicleListItem';
import { getUpcomingVehiclesMenu, postFile, updateUpcomingVehiclesMenu } from '../../services/mcApiService';
import { Alert } from '@material-ui/lab';
import * as _ from 'lodash';

const UpcomingVehiclesSelection = () => {
  const [listItems, setListItems] = useState([]);
  const [dynamoList, setDynamoList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState({});
  const [allowSave, setAllowSave] = useState(false);

  useEffect(() => {
    getUpcomingVehiclesMenu().then(data => {
      setListItems(data);
      setDynamoList(data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      console.log(`Error fetching menu data from Dynamo: ${error}`);
    });
  }, []);

  useEffect(() => {
    setAllowSave(!_.isEqual(dynamoList, listItems) && !uploading);
  }, [dynamoList, listItems, uploading]);

  const validateFields = (year, model, href) => {
    let errors = {};

    if (year === '') {
      errors.year = 'Year is requried';
    }

    if (model === '') {
      errors.model = 'Model is requried';
    }

    if (!href.startsWith('/')) {
      errors.href = 'URL should begin with a "/" Ex. /articles/upcoming_camry';
    }

    return errors;
  };

  const uploadImage = async (file, name) => {
    try {
      const fileName = name;
      const encodedData = await getBase64(file);
      const response = await postFile({
        encodedData,
        fileName,
        fileType: file.type,
        path: "static/images/jellybeans/"
      }, 'Images');

      return response;
    } catch (err) {
      throw new Error("Image upload failed.");
    }
  };

  const handleSaveChanges = async () => {
    try {
      setUploading(true);

      // Upload images to S3
      await Promise.all(
        listItems.map(async (item) => {
          if (item?.image instanceof File) {
            await uploadImage(item.image, item.rotatedImgSrc);
          }
        })
      );

      const dataForDynamo = listItems.map(item => {
        const temp = { ...item };
        delete temp['image'];
        temp.title = `${item.modelYear} ${item.model}`;
        return temp;
      });

      // Update Dynamo data
      await updateUpcomingVehiclesMenu(dataForDynamo);

      setDynamoList(listItems);
      setUploading(false);
      setMessage({ text: 'Upcoming vehicles menu successfully updated.', severity: 'success' });
    } catch (error) {
      setUploading(false);
      setMessage({ text: 'Upcoming vehicles menu update failed.', severity: 'error' });
      console.error("Error saving changes:", error);
    }
  };

  const addVehicleToList = (data) => {
    let newList = [...listItems];
    newList.push(data);
    const sortedList = _.orderBy(newList, ["model", "modelYear"]);
    setListItems(sortedList);
  };

  const editVehicleInList = (index, data) => {
    let newList = [...listItems];
    newList[index] = data;
    const sortedList = _.orderBy(newList, ["model", "modelYear"]);
    setListItems(sortedList);
  };

  const deleteVehicleFromList = (index) => {
    let newList = [...listItems];
    newList.splice(index, 1);
    const sortedList = _.orderBy(newList, ["model", "modelYear"]);
    setListItems(sortedList);
  };

  return (
    <div className='upcoming-vehicles-container'>
      <div className="menu-section">
        <p>Menu Items:</p>
        <List className="list-container">
          {!loading ?
            listItems.length > 0 ?
              listItems.map((item, index) => (
                <VehicleListItem
                  data={item}
                  deleteFunction={() => deleteVehicleFromList(index)}
                  editFunction={(data) => editVehicleInList(index, data)}
                  validateFunction={validateFields}
                  key={`${item.model}-${item.modelYear}`}
                />
              ))
              :
              <Alert severity='warning'>No upcoming vehicles. Use fields below to add vehicles to menu.</Alert>
            :
            <CircularProgress size={80} color="inherit" />
          }
        </List>
        <AddVehicleItem
          addFunction={(data) => addVehicleToList(data)}
          validateFunction={validateFields}
        />
        <Button onClick={handleSaveChanges} disabled={!allowSave} variant="contained" color="primary" className="save-changes-button">Save Changes</Button>
        {uploading ? <CircularProgress className="upcoming-vehicles-uploading-spinner" size={80} color="inherit" /> : null}
        <Snackbar open={message.text !== undefined} autoHideDuration={6000} onClose={() => setMessage({})}>
          <Alert onClose={() => setMessage({})} severity={message.severity}>
            {message.text}
          </Alert>
        </Snackbar>
      </div>
      <Alert severity='info'>
        Items in this list will be automatically sorted alphabetically by model name.
      </Alert>
    </div>
  );
};

export default UpcomingVehiclesSelection;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = ({ shouldWrap = false, width = "244px", chipHeight = 16 }) =>
  makeStyles(() => ({
    formControl: {
      width,
      textAlign: "left",
    },
    inputLabel: {
      pointerEvents: "auto",
    },
    infoIcon: {
      marginLeft: 4,
      verticalAlign: "middle",
      cursor: "pointer",
    },
    chips: {
      display: "flex",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      overflow: "auto",
      flexWrap: shouldWrap ? "wrap" : "unset",
    },
    chip: {
      margin: 2,
      height: chipHeight,
    },
  }))();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

export { MenuProps, useStyles };

import React, { useEffect, useState } from 'react';
import { getNotifications } from '../../services/mcApiService';
import ActivityToolbar from './activityToolbar';
import ActivityFilterChips from './activityFilterChips';
import ActivityTable from './activityTable';
import {
    Container,
} from '@material-ui/core';
import config from '../../config';

function NotificationActivity() {
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [filter, setFilter] = useState({
        text: '',
        category: 'Show All',
        type: 'Show All',
        status: 'Show All',
    });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const notificationTypes = ['Announcement', 'Push Notification', 'Outage Alert'];
    const lowerEnv = config.environments.formattedSourceEnvironment;
    const upperEnv = config.environments.formattedDestinationEnvironment;
    const statuses = [lowerEnv, 'Scheduled', upperEnv, 'Expired', 'Archived'];

    useEffect(() => {
        getNotifications()
            .then((notifications) => {
                setNotifications(defaultSort(notifications));
                setLoading(false);
            })
            .catch((err) => {
                setNotifications([]);
                setLoading(false);
            });
    }, [loading]);

    function defaultSort(data) {
        // Copy array & separate archived notifications
        let filteredArray = [...data].filter(item => !item.isArchived);
        let archivedArray = [...data].filter(item => item.isArchived);
        // Set sort date as promotedDate OR lastModifiedDate OR createdDate
        filteredArray = filteredArray.map(item => {
            let sortDate, status;
            if (isExpired(item.expirationDate)) {
                status = 'Expired';
                sortDate = item.promotedDate;
            } else if (item.isPromoted) {
                status = 'Promoted';
                sortDate = item.promotedDate;
            } else if (item.isScheduled) {
                status = 'Scheduled';
                sortDate = item.lastModifiedDate ? item.lastModifiedDate : item.createdDate;
            } else {
                status = 'Created';
                sortDate = item.lastModifiedDate ? item.lastModifiedDate : item.createdDate;
            }

            return { ...item, sortDate: sortDate, status: status };
        });
        // Sort the separate arrays
        filteredArray.sort((itemA, itemB) => compareDates(itemA.sortDate, itemB.sortDate));
        archivedArray.sort((itemA, itemB) => compareDates(itemA.lastModifiedDate, itemB.lastModifiedDate));
        // Tack the archived notifications on to the end
        filteredArray.push(...archivedArray);
        return filteredArray;
    }

    useEffect(() => {
        populateCategories();
        updateFilteredNotifications();
    }, [notifications, filter]);

    function populateCategories() {
        const categories = [];
        notifications?.forEach(item => {
            if (!categories.includes(item.category)) {
                categories.push(item.category);
            }
        });

        setCategories(categories.sort((a, b) => a.localeCompare(b)));
    }

    function updateFilteredNotifications() {
        if (!notifications) return;
        let filteredArray = [...notifications];
        // Filter by search text
        if (filter.text) {
            filteredArray = filteredArray.filter(item => findTextMatch(item, filter.text.toLowerCase()));
        }
        // Filter by category
        if (filter.category && filter.category !== 'Show All') {
            filteredArray = filteredArray.filter(item => item.category.toLowerCase() === filter.category.toLowerCase());
        }
        // Filter by type
        if (filter.type && filter.type !== 'Show All') {
            filteredArray = filteredArray.filter(item => {
                switch (filter.type) {
                    case 'Announcement': return item.category !== 'Outage Alert';
                    case 'Push Notification': return item.isPush;
                    case 'Outage Alert': return item.category === 'Outage Alert';
                }
            });
        }
        // Filter by status
        if (filter.status && filter.status !== 'Show All') {
            filteredArray = filteredArray.filter(item => {
                switch (filter.status) {
                    case lowerEnv: return !item.isPromoted && !item.isArchived;
                    case 'Scheduled': return item.isScheduled && !item.isPromoted && !item.isArchived;
                    case upperEnv: return item.isPromoted && !item.isArchived && !isExpired(item.expirationDate);
                    case 'Expired': return isExpired(item.expirationDate);
                    case 'Archived': return item.isArchived;
                }
            });
        }
        setFilteredNotifications(filteredArray);
    }

    function isExpired(date) {
        if (!date) return false;
        const expiredDate = new Date(date);
        const now = new Date();
        return expiredDate <= now;
    }

    function compareDates(dateA, dateB) {
        const a = new Date(dateA);
        const b = new Date(dateB);
        if (a > b) return -1;
        if (b < a) return 1;
        return 0;
    }

    function findTextMatch(item, filterText) {
        if (typeof filterText !== 'string') return;
        // Include if found in category, title, message, or push
        if (item.category?.toLowerCase().includes(filterText)) return true;
        if (item.title?.toLowerCase().includes(filterText)) return true;
        if (item.message?.toLowerCase().includes(filterText)) return true;
        if (filterText.toLowerCase() === 'push' && item.isPush) return true;
        return false;
    }

    function handleTextChange(value) {
        setFilter({
            ...filter,
            text: value
        });
    }

    function handleCategoryChange(value) {
        setFilter({
            ...filter,
            category: value
        });
    }

    function handleTypeChange(value) {
        setFilter({
            ...filter,
            type: value
        });
    }

    function handleStatusChange(value) {
        setFilter({
            ...filter,
            status: value
        });
    }

    const filterProps = { filter, categories, notificationTypes, statuses, handleTextChange, handleCategoryChange, handleTypeChange, handleStatusChange };

    return (
        <Container style={{ marginTop: 25, marginBottom: 25 }} >
            <ActivityToolbar {...filterProps} />
            <ActivityFilterChips {...filterProps} />
            <ActivityTable data={filteredNotifications} loading={loading} setLoading={setLoading} />
        </Container>
    );

}

export default NotificationActivity;

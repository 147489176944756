import React, { useEffect, useState, useCallback } from 'react';
import { List, Button, CircularProgress, Snackbar } from '@material-ui/core';
import './upcomingVehiclesPromoteMenu.css';
import VehicleListItem from './promoteVehicleListItem';
import { getUpcomingVehiclesMenu, promoteUpcomingVehiclesMenu } from '../../services/mcApiService';
import { Alert } from '@material-ui/lab';

const UpcomingVehiclesPromotion = () => {
  const [listItems, setListItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoting, setPromoting] = useState(false);
  const [message, setMessage] = useState("");
  const [promoted, setPromoted] = useState(false);

  // Function to Fetch Data
  const fetchVehicleMenu = async () => {
    try {
      const data = await getUpcomingVehiclesMenu();
      setListItems(data);
    } catch (error) {
      console.error("Error fetching menu data from Dynamo:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicleMenu();
  }, []);

  // Function to Handle Promotion
  const handlePromote = async () => {
    try {
      setPromoting(true);
      await promoteUpcomingVehiclesMenu();
      setPromoted(true);
      setMessage({ text: 'Upcoming vehicle menu item images have been successfully promoted.', severity: 'success' });
    } catch (error) {
      console.error("Error promoting images:", error);
      setMessage({ text: 'Failed to promote the upcoming vehicle menu item image.', severity: 'error' });
    } finally {
      setPromoting(false);
    }
  };

  return (
    <div className="container">
      <p>Menu Items:</p>
      <List className="list-container">
        {!loading ?
          listItems.map((item, index) => (
            <VehicleListItem
              data={item}
              key={`${item.model}-${item.modelYear}`}
            />
          ))
          :
          <CircularProgress size={80} color="inherit" />
        }
      </List>
      <Button onClick={handlePromote} disabled={promoted || promoting || loading} variant="contained" color="primary" className="promote-images-button">Promote Menu Data</Button>
      {promoting ? <CircularProgress className="upcoming-vehicles-promoting-spinner" size={80} color="inherit" /> : null}
      <Snackbar open={message.text !== undefined} autoHideDuration={6000} onClose={() => setMessage({})}>
        <Alert onClose={() => setMessage({})} severity={message.severity}>
          {message.text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UpcomingVehiclesPromotion;
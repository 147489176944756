import React, { useMemo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Card, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, Area, } from 'recharts';

/**
 * MetricGraph Component
 *
 * @param {string} title - The title to be displayed at the top of the component.
 * @param {object} graphProps - Configuration for the graph.
 *   @param {string} type - The type of graph to be displayed. Can be "Line" or "area". Default is "line".
 *   @param {boolean} graphProps.show - Flag to determine whether to show the graph.
 *   @param {array} graphProps.domain - The domain of the Y-axis. Default is [0, 'dataMax'].
 *   @param {string} graphProps.xDataKey - The key from the data source to be used for the X-axis values in the graph.
 *   @param {string} graphProps.lineDataKey - The key from the data source to be used for the Y-axis values (or data points) in the graph.
 *   @param {string} graphProps.lineColor - The color of the line in the graph.
 *   @param {string} graphProps.lineType - The type of line to be drawn on the graph. Refer to Recharts documentation for more information.
 *   @param {string} graphProps.xAxisLabel - The label for the X-axis.
 *   @param {string} graphProps.yAxisLabel - The label for the Y-axis.
 *   @param {number} graphProps.height - The height of the chart.
 *   @param {array} graphProps.data - The data to be displayed in the graph.
 * @param {object} searchProps - An object containing settings for the optional search input.
 *   @param {boolean} searchProps.show - Flag to determine whether to show the search input.
 *   @param {string} searchProps.label - The label for the search input field.
 *   @param {function} searchProps.onChange - Callback function to handle input changes.
 *   @param {string} searchProps.value - The current value of the search input.
 * @param {object} dropdownProps - Configuration for the dropdown selection (e.g., time periods).
 *   @param {boolean} dropdownProps.show - Flag to determine whether to show the dropdown.
 *   @param {string} dropdownProps.label - The label for the dropdown.
 *   @param {string} dropdownProps.value - The current selected value for the dropdown.
 *   @param {function} dropdownProps.onChange - Callback function to handle dropdown changes.
 *   @param {array} dropdownProps.items - Array of items to be displayed in the dropdown.
 * @param {array} datePickersProps - An array of objects configuring the optional date pickers.
 *   Each object has the following properties:
 *   @param {boolean} show - Flag to determine if the date picker should be shown.
 *   @param {string} label - The label to display for the date picker.
 *   @param {function} onChange - Callback function to handle date changes.
 *   @param {Date} value - The current selected date for the date picker.
 *   @param {string} format - The date format.
 */

const MetricGraph = ({
  title,
  graphProps = {
    show: false,
    lineColor: "#8884d8",
    lineType: "monotone",
    height: 300,
    data: [],
    type: "line",
    domain: [0, 'dataMax'],
  },
  searchProps = {
    show: false,
    label: "Search",
    onChange: () => { },
    value: "",
  },
  dropdownProps = {
    show: false,
    label: "Options",
    value: "",
    onChange: () => { },
    items: [],
  },
  datePickersProps = [{
    show: false,
    label: "Start Date",
    onChange: () => { },
    value: null,
    format: "MM/dd/yyyy",
  }, {
    show: false,
    label: "End Date",
    onChange: () => { },
    value: null,
    format: "MM/dd/yyyy",
  }],
}) => {
  const hasRightFields = useMemo(() => dropdownProps.show || datePickersProps.filter(d => d.show).length > 0, [dropdownProps.show, datePickersProps]);
  // Grab the number of digits for the y axis ticks so we can move the label accordingly
  const maxArticles = Math.max(...graphProps.data.map(item => item[graphProps.lineDataKey]));
  const maxArticlesDigits = maxArticles.toString().length;
  const dxForYAxis = -5 * maxArticlesDigits - 15

  return (
    <Card elevation={3} className='stretchy-card card-side-border' style={{ borderColor: graphProps.lineColor}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box p={3} border={1} borderColor="grey.300" borderRadius={2}>

          {title && (
            <Typography variant="h6" align="center" gutterBottom>
              {title}
            </Typography>
          )}

          <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={hasRightFields ? 9 : 12}>
              {searchProps.show && (
                <Box mb={4}>
                  <TextField
                    label={searchProps.label}
                    variant="outlined"
                    fullWidth
                    value={searchProps.value}
                    onChange={(e) => searchProps.onChange(e.target.value)}
                    InputProps={{
                      endAdornment: <i className="fas fa-search" />,
                    }}
                  />
                </Box>
              )}
              {graphProps.show && (
                <Box height={graphProps.height}>
                  <ResponsiveContainer width="100%" height="100%">
                    {graphProps.type === "area" ? (
                      <AreaChart data={graphProps.data} margin={{ left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={graphProps.xDataKey}
                          tickMargin={10}
                        >
                          <Label
                            value={graphProps.xAxisLabel}
                            position="outsideBottom"
                            dy={25}
                          />
                        </XAxis>
                        <YAxis
                          tickFormatter={(value) => new Intl.NumberFormat('en').format(value)}
                          domain={graphProps.domain}
                        >
                          <Label
                            value={graphProps.yAxisLabel}
                            position="outsideBottom"
                            angle={-90}
                            dx={dxForYAxis}
                          />
                        </YAxis>
                        <Tooltip />
                        <Area
                          type={graphProps.lineType}
                          dataKey={graphProps.lineDataKey}
                          stroke={graphProps.lineColor}
                          fill={graphProps.lineColor}
                          activeDot={{ r: 8 }}
                        />
                      </AreaChart>
                    ) : (
                      <LineChart data={graphProps.data} margin={{ left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={graphProps.xDataKey}
                          label={{ value: graphProps.xAxisLabel, position: 'outsideBottom', dy: 25 }}
                        />
                        <YAxis
                          tickFormatter={(value) => new Intl.NumberFormat('en').format(value)}
                          domain={graphProps.domain}
                        >
                          <Label
                            value={graphProps.yAxisLabel}
                            position="outsideBottom"
                            angle={-90}
                            dx={dxForYAxis}
                          />
                        </YAxis>
                        <Tooltip />
                        <Line
                          type={graphProps.lineType}
                          dataKey={graphProps.lineDataKey}
                          stroke={graphProps.lineColor}
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    )}
                  </ResponsiveContainer>
                </Box>
              )}
            </Grid>

            {hasRightFields && (
              <Grid item container xs={12} sm={3}>
                {dropdownProps.show && (
                  <Grid item xs={12}>
                    <Box mb={4}>
                      <TextField
                        select
                        label={dropdownProps.label}
                        value={dropdownProps.value}
                        onChange={(e) => dropdownProps.onChange(e.target.value)}
                        fullWidth
                        variant="outlined"
                      >
                        {dropdownProps.items.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                )}

                {datePickersProps.map(datePicker => {
                  if (datePicker.show) {
                    return (
                      <Grid key={datePicker.label} item xs={12}>
                        <Box mb={4}>
                          <DatePicker
                            clearable
                            label={datePicker.label}
                            value={datePicker.value}
                            onChange={(newValue) => datePicker.onChange(newValue)}
                            fullWidth
                            format={datePicker.format}
                            inputVariant="outlined"
                          />
                        </Box>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            )}
          </Grid>
        </Box>
      </MuiPickersUtilsProvider>
    </Card>
  );
};

export default MetricGraph;

import React, { useEffect, useState } from 'react';
import ActivityCard from '../../components/ActivityCard';
// import { getTemplateData } from '../../mcApiService';
import {
    Chip,
} from '@material-ui/core';
import {
    ViewList as TemplateIcon,
} from '@material-ui/icons';
import config from '../../config';


function TemplateActivityCard(props) {
    const [templateData, setTemplateData] = useState();
    const [tableData, setTableData] = useState();
    const rowLimit = 4;

    useEffect(() => {
        // Get template data from misson-control-api
        // getTemplateData().then(data => setTemplateData(data.Items));
    }, []);

    useEffect(() => {
        if (!templateData) return;
        // Format template data to table array of objects consumable by ActivityCard component
        // const tableArray = [];
        // for (let i = 0; i < Math.min(rowLimit, templateData.length); i++) {
        //     tableArray.push({
        //         title: '',
        //         dateTime: formatDateTime(''),
        //         env: envChip(),
        //         status: statusChip(),
        //     });
        // }
        // setTableData(tableArray);
    }, [templateData]);

    function formatDateTime(dateTime) {
        return new Date(dateTime).toLocaleString('en-US', { timeZone: "America/Chicago", dateStyle: 'short', timeStyle: 'short' });
    }

    function envChip(env) {
        const style = {};
        switch (env.toLowerCase()) {
            case config.environments.sourceEnvironment:
                style.backgroundColor = '#F8D76B';
                style.color = '#58595B'
                break;
            case config.environments.destinationEnvironment:
                style.backgroundColor = '#7A306C';
                style.color = '#FFFFFF'
                break;
        }
        return (
            <Chip
                size='small'
                style={style}
                label={env}
            />
        );
    }

    function statusChip(status) {
        const style = {};
        switch (status) {
            case 'Success':
                style.backgroundColor = '#72B85F';
                style.color = '#FFFFFF'
                break;
            case 'Error':
                style.backgroundColor = '#EB0A1E';
                style.color = '#FFFFFF'
                break;
            case 'Scheduled':
                style.backgroundColor = '#57C2D8';
                style.color = '#FFFFFF'
                break;
        }
        return (
            <Chip
                size='small'
                style={style}
                label={status}
            />
        );
    }

    return (
        <ActivityCard
            titleIcon={<TemplateIcon />}
            title='Latest Template Activity'
            // tableData={tableData}
            variant='activity'
            allowAccess={props.allowAccess}
            comingSoon
        />
    );
}

export default TemplateActivityCard;
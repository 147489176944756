import React from 'react';

// MATERIAL-UI COMPONENTS
import { Container, Grid, Button } from '@material-ui/core';

// Reusable components
import PageViewsComponent from './DashboardComponents/PageViewsComponent';
import BoxesComponents from './DashboardComponents/BoxesComponent';
import NotificationSelectorModalWrapper from './DashboardComponents/NotificationSelectorModalWrapper';
import ArticleCountComponent from './DashboardComponents/ArticleCountComponent';
import ActiveUsersComponent from './DashboardComponents/ActiveUsersComponent';


function AnalyticsDashboardComponent() {

    return <Container style={{ marginTop: '25px' }} >
        <h1>Analytics Dashboard Page Template</h1>
        <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
                <BoxesComponents />
            </Grid>
            <Grid item md={6} xs={12} >
                <PageViewsComponent />
            </Grid>
            <Grid item md={6} xs={12} >
                <ArticleCountComponent />
            </Grid>
            <Grid item md={6} xs={12} >
                <ActiveUsersComponent />
            </Grid>
            <Grid item xs={12} >
                <NotificationSelectorModalWrapper>
                    <Button variant="outlined" color="primary">
                        Open Notification Selector
                    </Button>
                </NotificationSelectorModalWrapper>
            </Grid>

        </Grid>
    </Container>;
}

export default AnalyticsDashboardComponent;
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { FilterList as FilterIcon } from "@material-ui/icons";

import "./style.css";
import {
  getNotificationFilters,
  queryAllDealerships,
  queryAllRegions,
  queryNumRecipients,
  saveNotificationFilters,
} from "../../services/mcApiService";
import FilterPopover from "./filterPopover";
import MultiSelect from "../MultiSelect";
import TextFieldToolTip from "./textFieldToolTip";

const FILTER_TYPES = [
  {
    value: "pagesVisited",
    label: "Page(s) visited",
  },
  {
    value: "activeDate",
    label: "Last Active Date",
  },
  {
    value: "regions",
    label: "Regions",
  },
  {
    value: "dealerships",
    label: "Dealerships",
  },
];

const RecipientsModal = (props) => {
  const [allDealerships, setAllDealerships] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtersLoading, setFiltersLoading] = useState(true);
  const [isRecipientSaving, setIsRecipientSaving] = useState(false);
  const [numFilteredUsers, setNumFilteredUsers] = useState(0);
  const [numRecipientsLoading, setNumRecipientsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);

  // Get list of available regions to filter on
  const getAllRegions = async () => {
    const allRegions = await queryAllRegions();
    allRegions.forEach(region => region.regionCode = parseInt(region.regionCode));
    setAllRegions(allRegions);
  };

  // Get list of available regions to filter on
  const getAllDealerships = async () => {
    const allDealerships = await queryAllDealerships();
    allDealerships.forEach(dealership => {
      dealership.dealerCode = parseInt(dealership.dealerCode);
      dealership.regionCode = parseInt(dealership.regionCode);
    });
    setAllDealerships(allDealerships);
  };

  // STEP 1: Get all the available filters, e.g. regions and dealerships
  useEffect(() => {
    const queryAllFilters = async () => {
      try {
        await getAllRegions();
        await getAllDealerships();
      } catch (err) {
        alert(`Error getting all filters: ${JSON.stringify(err)}`);
      }
    };
    queryAllFilters();
  }, []);

  // Get existing filters from a given notification ID
  const queryExistingFilters = async (notificationId) => {
    const { data: queriedFilters } = await getNotificationFilters(notificationId);
    const newFilters = {
      ...(queriedFilters?.regions?.length > 0 ? { regions: queriedFilters?.regions.map(region => parseInt(region)) } : {}),
      ...(queriedFilters?.dealerships?.length > 0 ? { dealerships: queriedFilters?.dealerships.map(dealerCode => parseInt(dealerCode)) } : {}),
    };

    // Set selected filter types based on filter data present in response
    const newFilterTypeValues = Object.keys(queriedFilters).filter(key => !!queriedFilters[key] && !!queriedFilters[key].length);
    const newFilterTypes = FILTER_TYPES.filter(filter => newFilterTypeValues.includes(filter.value));

    setSelectedFilters(newFilters);
    setSelectedFilterTypes(newFilterTypes);
  };

  // STEP 2: Query any currently existing filters
  useEffect(() => {
    if (props.notificationId && allRegions?.length > 0 && allDealerships?.length > 0) {
      const getExistingFilters = async () => {
        try {
          setFiltersLoading(true);
          queryExistingFilters(props.notificationId);
        } catch (err) {
          alert(`Error getting recipients: ${JSON.stringify(err)}`);
        } finally {
          setFiltersLoading(false);
        }
      };
      getExistingFilters();
    }
  }, [props.notificationId, allRegions, allDealerships]);

  // Toggle a filter type on or off from the "FILTERS" button
  const toggleFilterType = (value) => {
    // Get the index of the filter type that was toggled
    const existingIndex = selectedFilterTypes.findIndex(
      (filterType) => filterType.value === value
    );
    // If the filter type is already selected, remove it. Otherwise, add it.
    if (existingIndex !== -1) {
      setSelectedFilterTypes(selectedFilterTypes.filter(filterType => filterType.value !== value));
      // Clear out the previous filter data for the removed type
      setSelectedFilters({ ...selectedFilters, [value]: undefined });
    } else {
      setSelectedFilterTypes([...selectedFilterTypes, FILTER_TYPES.find(filterType => filterType.value === value)]);
    }
  };

  // Saves all the filter data into the db
  const handleSaveRecipients = async () => {
    try {
      setIsRecipientSaving(true);
      await saveNotificationFilters({ ...selectedFilters, notificationId: props.notificationId });
      alert(`Recipients saved successfuly.`);
      props.closeDialog();
    } catch (err) {
      alert(`Error saving recipients: ${JSON.stringify(err)}`);
    } finally {
      setIsRecipientSaving(false);
    }
  };

  // Any time the selected filters get changed, query mcApi to get the new number of filtered users
  useEffect(() => {
    const queryNumFilteredUsers = async () => {
      try {
        const { data } = await queryNumRecipients(selectedFilters);
        setNumFilteredUsers(data?.user_count);
      } catch (err) {
        alert(`Error filtering recipients: ${JSON.stringify(err)}`);
      } finally {
        setNumRecipientsLoading(false);
      }
    };
    if (!filtersLoading) {
      setNumRecipientsLoading(true);
      queryNumFilteredUsers();
    }
  }, [selectedFilters]);

  return (
    <Dialog open={props.open} onClose={() => props.closeDialog()} fullWidth>
      <div id="filter-modal-title">
        <DialogTitle>{props.notification.title} - Customize Notification Recipients</DialogTitle>
        <div style={{ paddingRight: 20 }}>
          <Button
            startIcon={<FilterIcon />}
            variant="outlined"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            Filters
          </Button>
          <FilterPopover
            allFilterTypes={FILTER_TYPES}
            selectedFilterTypes={selectedFilterTypes}
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
            onToggle={toggleFilterType}
          />
        </div>
      </div>
      <DialogContent style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
        {!filtersLoading &&
          <>
            {/* {
              // Page visited
              selectedFilterTypes.some(filterType => filterType.value == "pagesVisited") &&
              <TextFieldToolTip
                value={selectedFilters?.pagesVisited}
                onValueChange={pagesVisited => setSelectedFilters({ ...selectedFilters, pagesVisited })}
                title={"Path"}
                tooltipText={"Pages Visited"}
                type={"text"}
                disabled={isRecipientSaving}
              />
            } */}
            {
              // Last Active Date
              selectedFilterTypes.some(filterType => filterType.value == "activeDate") &&
              <TextFieldToolTip
                value={selectedFilters?.activeDate}
                onValueChange={activeDate => setSelectedFilters({ ...selectedFilters, activeDate })}
                title={"Last Active Date"}
                tooltipText={"Only users that haven't logged in since this date or earlier will receive the notification."}
                type={"date"}
                disabled={isRecipientSaving}
              />
            }
            {
              // Regions
              selectedFilterTypes.some(filterType => filterType.value == "regions") &&
              <MultiSelect
                width="100%"
                items={allRegions.map(region => ({ label: region.regionName, value: region.regionCode }))}
                title="Regions"
                tooltipText="Only users in the selected regions will receive notifications."
                value={selectedFilters.regions ?? []}
                disabled={isRecipientSaving}
                onChange={(newRegionCodes) => {
                  // Remove any dealership filters that aren't in one of the filtered regions
                  if (newRegionCodes?.length > 0) {
                    const filteredDealerships = selectedFilters.dealerships?.filter(dealershipCode => {
                      const dealership = allDealerships.find(d => d.dealerCode === dealershipCode);
                      return dealership && newRegionCodes.includes(dealership.regionCode);
                    });
                    setSelectedFilters({ ...selectedFilters, regions: newRegionCodes, dealerships: filteredDealerships });
                  } else {
                    setSelectedFilters({ ...selectedFilters, regions: undefined });
                  }
                }}
                chipHeight={30}
              />
            }
            {
              // Dealerships
              selectedFilterTypes.some(filterType => filterType.value == "dealerships") &&
              <MultiSelect
                width="100%"
                items={!selectedFilters.regions
                  ? allDealerships.map(dealership => ({ label: dealership.dealerName, value: dealership.dealerCode }))
                  : allDealerships.filter(dealership => selectedFilters.regions?.includes(dealership.regionCode)).map(dealership => ({ label: dealership.dealerName, value: dealership.dealerCode }))}
                title="Dealerships"
                tooltipText="Only users in the selected dealerships will receive notifications. If filtering by both regions and dealerships, only dealerships in the selected regions will be shown in the list, but only users in the selected dealerships will receive notifications."
                value={selectedFilters.dealerships ?? []}
                disabled={isRecipientSaving}
                onChange={(newDealerCodes) => {
                  setSelectedFilters({ ...selectedFilters, dealerships: newDealerCodes.length > 0 ? newDealerCodes : undefined });
                }}
                chipHeight={30}
              />
            }
            <span className="filter-result">
              Total users impacted by filters:
              {/* If there are no filters being applied, just show All Users instead of a number */}
              {!numRecipientsLoading && <b>{(!selectedFilterTypes.length || FILTER_TYPES.map((filterType) => filterType.value).every((filterType) => !selectedFilters[filterType])) ? 'All Users' : numFilteredUsers}</b>}
              {numRecipientsLoading && <b><CircularProgress size="18px" color="inherit" /></b>}
            </span>
          </>
        }
        {filtersLoading && <CircularProgress size="20px" color="inherit" />}
        <DialogActions>
          {!isRecipientSaving &&
            <>
              <Button
                variant="contained"
                disabled={filtersLoading || isRecipientSaving}
                onClick={() => props.closeDialog()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={filtersLoading || isRecipientSaving}
                onClick={handleSaveRecipients}
              >
                Save
              </Button>
            </>
          }
          {isRecipientSaving && <span><CircularProgress size="12px" color="inherit" /> Saving...</span>}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RecipientsModal;

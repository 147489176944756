const MEETINGS_IN_A_BOX_VALUE = `<p><span style="font-family:ToyotaType-Bold;"> Insert Image here. Warning: Ensure Proper Resolution for Image</span></p><p><span style="font-family:ToyotaType-Bold;font-size:35px;">Start Here</span></p><p><span style="font-family:ToyotaType-Regular;font-size:20px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce commodo ligula non lorem dignissim scelerisque.</span></p><p/><p/><p/><p><span style="font-family:ToyotaType-Regular;font-size:20px;">Use this handy PDF to learn how to execute each training package.</span></p><p></p><div class="horizontal-line-widget">&nbsp;</div><p></p><p><span style="font-family:ToyotaType-Bold;font-size:35px;"> Key Training Guide </span><span style="font-family:ToyotaType-Regular;font-size:35px;"> (10 minutes) </span></p><p><span style="font-family:ToyotaType-Regular;font-size:20px;">Key training description</span></p><p></p><p><span style="font-family:ToyotaType-Regular;font-size:20px;">Use this handy PDF to Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></p><p></p><p><span style="font-family:ToyotaType-Bold;font-size:25px;">Key Training Materials</span><span style="font-family:ToyotaType-Light;font-size:25px;">(3 items)</span></p><p></p><p></p><p></p><p></p><p></p><div class="horizontal-line-widget">&nbsp;</div><p></p><p><span style="font-family:ToyotaType-Bold;font-size:35px;">Core Training Guide </span><span style="font-family:ToyotaType-Regular;font-size:35px;">(15 minutes)</span>
</p><p><span style="font-family:ToyotaType-Regular;font-size:20px;"> Core training description</span></p><p></p><p></p><p><span style="font-family:ToyotaType-Regular;font-size:20px;"> Use this handy PDF to Lorem ipsum dolor sit amet, consectetur adipiscing elit </span></p><p></p><p><span style="font-family:ToyotaType-Bold;font-size:25px;">Core Training Materials </span><span style="font-family:ToyotaType-Light;font-size:25px;">(3 items)</span></p><p></p><p></p><p></p><div class="horizontal-line-widget">&nbsp;</div><p></p><p><span style="font-family:ToyotaType-Bold;font-size:35px;">Enhanced Training Guide </span><span style="font-family:ToyotaType-Regular;font-size:35px;">(23 minutes)</span></p><p><span style="font-family:ToyotaType-Regular;font-size:20px;">Enhanced training description</span></p><p></p><p></p><p><span style="font-family:ToyotaType-Regular;font-size:20px;">Use this handy PDF to Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></p><p></p><p><span style="font-family:ToyotaType-Bold;font-size:25px;">Enhanced Training Materials</span><span style="font-family:ToyotaType-Regular;font-size:25px;"> </span><span style="font-family:ToyotaType-Light;font-size:25px;">(3 items)</span></p><p></p><p></p><p></p><div class="horizontal-line-widget">&nbsp;</div><p></p><p><span style="font-family:ToyotaType-Bold;font-size:35px;">All Training Materials</span></p><p></p><p></p>`;

// Warning: If we eventually add more templates, this Map would need to be updated accordingly.
// Long term solution would be to store this in DynamoDB, if we will eventually allow users
// to create their own "templates" to use in the text editor
const TEMPLATE_MAP = {
    'Meetings in a Box': MEETINGS_IN_A_BOX_VALUE
};

export const TEMPLATE_OPTIONS = Object.keys(TEMPLATE_MAP);

export function getTemplateValue(templateValue) {
    return TEMPLATE_MAP[templateValue];
}
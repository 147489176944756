import Axios from 'axios';

import { GET_ARTICLES_METADATA_ENDPOINT, GET_USERS_METADATA_ENDPOINT, GET_PAGE_VIEWS_ENDPOINT } from '../constants';
import { getToken } from '../auth';
import config from '../config';

export function getArticlesMetadata({ articleCategory, startDate, endDate }) {
  return new Promise((resolve, reject) => {
    getToken().then((token) => {
      let apiURL = config.apiGateway.URL + GET_ARTICLES_METADATA_ENDPOINT + '?';

      if (articleCategory) {
        apiURL += `&articleCategory=${articleCategory}`;
      }
      if (startDate) {
        apiURL += `&startDate=${startDate}`
      }
      if (endDate) {
        apiURL += `&endDate=${endDate}`
      }

      Axios.get(apiURL, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          console.log("Exception " + err);
          return reject(err);
        });
    });
  });
}

export function getUsersMetadata({ startDate, endDate }) {
  return new Promise((resolve, reject) => {
    getToken().then((token) => {
      let apiURL = config.apiGateway.URL + GET_USERS_METADATA_ENDPOINT + '?';

      if (startDate) {
        apiURL += `&startDate=${startDate}`
      }
      if (endDate) {
        apiURL += `&endDate=${endDate}`
      }

      Axios.get(apiURL, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          console.log("Exception " + err);

          return reject(err);
        });
    });
  });
}

export async function getPageViews({ pagePath, startDate, endDate }) {
  try {
    const token = await getToken();
    const response = await Axios.get(config.apiGateway.URL + GET_PAGE_VIEWS_ENDPOINT, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: {
        pagePath,
        startDate,
        endDate,
      }
    });
    return response.data;

  } catch (err) {
    console.error("Exception: ", err);
    throw err;
  }
}

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { getArticle } from '../../services/mcApiService';
import '../../App.css';
import { getEnvironments } from '../../utils';
import CreateArticle from '../CreateArticle';


export default class UpdateArticleComponent extends React.Component {

    constructor(props) {
        super(props);
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.state = {
            articleUrl: "",
            articleExists: false,
            articleTitle: "",
            articleId: "",
            articleDescription: "",
            articleContent: [],
            textContent: '',
            selectedVehicleTags: [],
            selectedSubjectTags: [],
            keywords: [],
            internalArticle: false
        };
        this.getArticleContent = this.getArticleContent.bind(this)
        this.setArticleContent = this.setArticleContent.bind(this);
        this.findArticle = this.findArticle.bind(this);
        this.onArticleLinkInputChange = this.onArticleLinkInputChange.bind(this);
        this.getListOfArticleFileReferences = this.getListOfArticleFileReferences.bind(this);
        this.formatArticleFileReference = this.formatArticleFileReference.bind(this);
        this.formatArticleDescription = this.formatArticleDescription.bind(this);
        this.formatArticleContent = this.formatArticleContent.bind(this);
    }

    findArticle = async () => {
        if (!this.state.articleUrl) {
            alert("Please fill in all required fields");
        } else {
            const article = await this.getArticleContent();
            if (!article || article.error) {
                alert("Could not find article. Please check that the provided URL is correct and try again.");
            }
        }
    }

    onArticleLinkInputChange = event => {
        this.setState({
            articleUrl: event.target.value.trim(),
        });
    }

    // Takes in an array of file objects and returns an array of file name strings
    getListOfArticleFileReferences = articleContentArray => {
        const files = articleContentArray.filter(item => item.contentType.toLowerCase() === 'file');
        return files.map(file => this.formatArticleFileReference(file));
    }

    // Takes in a single file object and returns a string value
    formatArticleFileReference = fileObject => {
        const rawFileReference = fileObject.contentValue;
        const updatedDomainReference = rawFileReference.replace('cf.eshowroom-stage.deops.toyota.com', `${this.sourceEnvironment}.engage.toyota.com`);
        return updatedDomainReference;
    }

    formatArticleDescription = articleDescription => {
        let cleanDescription;
        if (articleDescription) {
            cleanDescription = articleDescription.replace("<p> </p>", "<p><br></p>");
        }
        return cleanDescription;
    }

    formatArticleContent = content => {
        let textContent = '';
        for (let item in content) {
            switch (content[item].contentType.toLowerCase()) {
                case 'text':
                    textContent = `${textContent}${content[item].contentValue}`;
                    break;
                case 'subtitle':
                    textContent = `${textContent}<h3>${content[item].contentValue}</h3>`;
                    break;
                case 'image':
                    textContent = `${textContent}<img src="${content[item].contentValue.replace('cf.eshowroom-stage.deops.toyota.com', `${this.sourceEnvironment}.engage.toyota.com`)}"/>`;
                    break;
                case 'video':
                    const videoUrl = `https://video-toyota.qumucloud.com/view/${content[item].contentValue}?inline=true`;
                    textContent = `${textContent}<iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${videoUrl}></iframe><p><br></p>`;
                    break;
                case 'link':
                    textContent = `${textContent}(*${content[item].contentValue}*)`;
                    break;
                default:
                    break;
            }
        }
        return textContent;
    }

    setArticleContent = articleData => {
        try {
            const bodyContent = typeof articleData.content === 'string' ? JSON.parse(articleData.content) : articleData.content;
            if (bodyContent.indexOf('Article was not found') > -1) {
                alert('Article was not found. Double check the URL and try again.');
            }
            else {
                const articleFileAttachments = this.getListOfArticleFileReferences(bodyContent);

                // all articles should have an array of categories containing all category titles for that article
                // if the article does not have an existing categories array, create one with the category string (now a deprecated attribute!)
                // if no category string, empty array will be passed in
                this.setState({
                    articleContent: bodyContent,
                    articleDate: new Date(articleData.createdDate),
                    articleDescription: this.formatArticleDescription(articleData.description),
                    articleFormat: articleData.format,
                    articleId: articleData.id,
                    articleTitle: articleData.title,
                    categories: articleData.categories || [articleData.category],
                    internalArticle: !articleData.tags.includes("Customer Sharing"),
                    keywords: articleData.keywords ? (typeof articleData.keywords === 'string' ? JSON.parse(articleData.keywords) : articleData.keywords) : [],
                    previouslyUploadedFiles: articleFileAttachments ? articleFileAttachments : null,
                    selectedSubjectTags: articleData.tags ? (typeof articleData.tags === 'string' ? JSON.parse(articleData.tags) : articleData.tags) : [],
                    selectedVehicleTags: articleData.vehicles ? (typeof articleData.vehicles === 'string' ? JSON.parse(articleData.vehicles) : articleData.vehicles) : [],
                    textContent: this.formatArticleContent(bodyContent),
                    thumbnail: articleData.thumbnail,
                    thumbnailUrl: articleData.thumbnail,
                    videoHideShare: articleData.videoHideShare || false,
                    isRecommended: articleData.isRecommended || false,
                    hideDownloadButton: articleData.hideDownloadButton || false,
                    articleExists: true,
                    lastPromotedArticleId: articleData.lastPromotedArticleId
                });
            }
        }
        catch (ex) {
            alert("There was an error formatting article data for updates. Please try again or contact the dev team.");
            window.location.reload();
        }
    }

    getArticleContent = () => {
        const urlArray = this.state.articleUrl.split('/');
        const articleId = urlArray.slice(-1)[0];

        return new Promise((resolve, reject) => {
            getArticle(articleId)
                .then(response => {
                    this.setArticleContent(response);
                    resolve(response);
                })
                .catch(error => {
                    console.log("Exception: " + error);
                    alert("There was an error retrieving the article. Please try again.")
                    reject(error);
                });
        });
    }


    render() {
        return (
            <div>
                <h1 className="App-title" style={{ display: this.state.articleExists ? "none" : "normal" }}>Find Article by URL</h1>
                <div className="Find-Article" style={{ display: this.state.articleExists ? "none" : "normal" }}>
                    <TextField
                        className="Article-URL"
                        hintText={"Enter the " + this.sourceEnvironment + " Article URL"}
                        floatingLabelText="Article URL"
                        onChange={this.onArticleLinkInputChange}
                        fullWidth={true}
                        required={true}
                        errorText={this.state.articleUrl ? "" : "This field is required"}
                    />
                    <br />
                    <br />
                    <div className="Find-Article-Button">
                        <Button variant='contained' color='secondary' onClick={this.findArticle} >Find Article</Button>
                    </div>
                </div>
                {this.state.articleExists ? (
                    <div className="Article-Information" style={{ display: "inline" }} >
                    <CreateArticle
                        articleContent={this.state.articleContent}
                        articleDate={this.state.articleDate}
                        articleDescription={this.state.articleDescription}
                        articleFormat={this.state.articleFormat}
                        articleId={this.state.articleId}
                        articleTitle={this.state.articleTitle}
                        categories={this.state.categories}
                        internalArticle={this.state.internalArticle}
                        keywords={this.state.keywords}
                        previouslyUploadedFiles={this.state.previouslyUploadedFiles}
                        selectedSubjectTags={this.state.selectedSubjectTags}
                        selectedVehicleTags={this.state.selectedVehicleTags}
                        textContent={this.state.textContent}
                        thumbnail={this.state.thumbnail}
                        thumbnailUrl={this.state.thumbnailUrl}
                        videoHideShare={this.state.videoHideShare}
                        isRecommended={this.state.isRecommended}
                        hideDownloadButton={this.state.hideDownloadButton}
                        lastPromotedArticleId={this.state.lastPromotedArticleId}
                        updateArticle
                    />
                </div>
                ) : null}
            </div>
        );
    }
}
import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import {
  Skeleton,
  Alert
} from '@material-ui/lab';
import {
  Publish as PublishIcon,
  Edit as EditIcon,
  Notifications as NotificationIcon,
  Archive as ArchiveIcon,
  Person as PersonIcon
} from '@material-ui/icons';

import { FeedbackContext } from '../../components/FeedbackSnackbar';
import { getNotificationCategories } from '../../services/mcApiService';
import ArchivedChip from '../../components/ActivityTableComponents/archivedChip';
import ArchiveDialog from './archiveDialog';
import CategoryChip from '../../components/ActivityTableComponents/categoryChip';
import config from '../../config';
import CreatedChip from '../../components/ActivityTableComponents/createdChip';
import EditDatesDialog from './editDatesDialog';
import ExpirationChip from '../../components/ActivityTableComponents/expirationChip';
import PromotedChip from '../../components/ActivityTableComponents/promotedChip';
import PromoteDialog from './promoteDialog';
import RecipientsModal from '../../components/RecipientsModal';
import ScheduledChip from '../../components/ActivityTableComponents/scheduledChip';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparatorField = (object, orderBy) => {
  if (orderBy === 'subprodStatus') {
    if (object.lastModifiedDate) {
      return 'lastModifiedDate';
    }

    return 'createdDate';
  } else if (orderBy === 'prodStatus') {
    if (object.isScheduled) {
      return 'scheduledDate';
    }

    if (object.isPromoted) {
      return 'promotedDate';
    }

    return null;

  } else if (orderBy === 'expiration' && object.expirationDate) {
    return 'expirationDate';
  }

  return null;
};

function descendingDateComparator(a, b, orderBy) {
  const aField = getComparatorField(a, orderBy);
  const bField = getComparatorField(b, orderBy);
  const bValue = bField ? b[bField] : '0';
  const aValue = aField ? a[aField] : '0';

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;

}

function getComparator(order, orderBy) {
  const dateList = ['subprodStatus', 'prodStatus', 'expiration'];

  if (dateList.includes(orderBy)) {
    return order === 'desc'
      ? (a, b) => descendingDateComparator(a, b, orderBy)
      : (a, b) => -descendingDateComparator(a, b, orderBy);
  }

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => onRequestSort(event, property);
  const subProdStatus = config.environments.formattedSourceEnvironment;
  const prodStatus = config.environments.formattedDestinationEnvironment == "Prod" ? "Production" : config.environments.formattedDestinationEnvironment;

  const headCells = [
    { id: 'category', label: 'Category' },
    { id: 'title', label: 'Title' },
    { id: 'message', label: 'Message' },
    { id: 'subprodStatus', label: subProdStatus },
    { id: 'prodStatus', label: prodStatus },
    { id: 'expiration', label: 'Expiration' },
  ];

  return (
    <TableHead>
      <TableRow sx={{ width: '100%' }} >
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: 'bold', textAlign: 'center' }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? <></> : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }} >Push</TableCell>
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }} >Edit</TableCell>
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }} >Recipients</TableCell>
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }} >Promote</TableCell>
        <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }} >Archive</TableCell>
      </TableRow>
    </TableHead>
  );
}

function ActivityTable(props) {
  const [categories, setCategories] = useState();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('default');
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [8, 25, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [contentSelected, setContentSelected] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const { snackbar, setSnackbar } = useContext(FeedbackContext);

  const handleCopyToClipboard = (id) => {
    // Copy ID to the clipboard
    navigator.clipboard.writeText(id).then(() => {
      // Show the toast notification
      setOpenToast(true);
    });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const openDialog = (e, content, action) => {
    e.stopPropagation();
    setContentSelected(content);
    setDialogAction(action);
    setSnackbar({ open: false });
    setDialogOpen(true);
  };

  const closeDialog = (success, message) => {
    setDialogOpen(false);
    // If closing dialog, success will read as false. Checking that message was passed before opening the snackbar
    message && setSnackbar({ open: true, success: success, message: message });
    (success && message) && props.setLoading(true);
    setDialogAction(null);
    setContentSelected(null);
  };

  // Initialize category data
  useEffect(() => {
    if (!categories) {
      getNotificationCategories()
        .then(data => {
          if (data) {
            setCategories(data.categories);
          } else {
            console.log("Error getting notificaion categories");
          }
        });
    }
  }, []);

  useEffect(() => {
    // set snackbar state from previous page and clear current history state so back nav doesn't display snackbar again
    // react-router-dom v5 can do this cleaner with useLocation state
    window.history.state?.snackbar && setSnackbar({ ...window.history.state.snackbar });
    window.history.replaceState({}, '', '/notifications/activity');
  }, []);

  const getActivityDialog = () => {
    switch (dialogAction) {
      case "promote":
        return <PromoteDialog notification={contentSelected} open={dialogOpen} closeDialog={(success, message) => closeDialog(success, message)} />;
      case "archive":
        return <ArchiveDialog notification={contentSelected} open={dialogOpen} closeDialog={(success, message) => closeDialog(success, message)} />;
      case "editDates":
        return <EditDatesDialog notification={contentSelected} open={dialogOpen} closeDialog={(success, message) => closeDialog(success, message)} isActive={!checkExpired(contentSelected.expirationDate)} />;
      case "editRecipients":
        return <RecipientsModal notificationId={contentSelected.id} notification={contentSelected} open={dialogOpen} closeDialog={(success, message) => closeDialog(success, message)} />;
      default:
        return <></>;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    orderBy === property && order === 'desc' ? setOrderBy("default") : setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checkExpired = (date) => {
    if (date) {
      const expiredDate = new Date(date);
      const now = new Date();

      return expiredDate <= now;
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - props.data?.length);
  const textCellStyle = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '100px' };

  if (!props.data) return <Skeleton variant='rect' height={750} />;

  return <>
    <Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} style={{ position: 'relative' }} >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
              {props.data.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(data => {
                  return (
                    <TableRow hover tabIndex={-1} key={data.id} style={{ height: 81, cursor: "pointer" }} onClick={() => handleCopyToClipboard(data.id)}>
                      {/* CATEGORY Column */}
                      <TableCell><CategoryChip notification={data} categories={categories} /></TableCell>

                      {/* TITLE Column */}
                      <Tooltip enterDelay={500} title={data.title?.length > 15 ? data.title : ''} >
                        <TableCell style={textCellStyle}>
                          {data.title}
                        </TableCell>
                      </Tooltip>

                      {/* MESSAGE Column */}
                      <Tooltip enterDelay={500} title={data.message?.length > 15 ? data.message : ''} >
                        <TableCell style={textCellStyle}>
                          {data.message}
                        </TableCell>
                      </Tooltip>

                      {/* [LOWER ENV] Column */}
                      <TableCell>
                        {data.isArchived ? <ArchivedChip />
                          : <CreatedChip date={data.lastModifiedDate || data.createdDate} expired={checkExpired(data.expirationDate)} />}
                      </TableCell>

                      {/* [UPPER ENV] Column */}
                      <TableCell>
                        {/* click blocker prevents clicks when inside a modal, does nothing when inside Notification page */}
                        <div className='click-blocker' onClick={() => { }}></div>
                        {data.isScheduled && data.scheduledDate ?
                          <Tooltip title={checkExpired(data.expirationDate) ? "" : "Edit Scheduled Dates"} arrow placement="top-start" >
                            <div>
                              <ScheduledChip date={data.scheduledDate} expired={checkExpired(data.expirationDate)} onClick={!checkExpired(data.expirationDate) ? (e) => openDialog(e, data, "editDates") : null} />
                            </div>
                          </Tooltip>
                          : (data.isPromoted && <PromotedChip date={data.promotedDate} expired={checkExpired(data.expirationDate)} />)}
                      </TableCell>
                      {/* EXPIRATION Column */}
                      <TableCell>
                        {/* click blocker prevents clicks when inside a modal, does nothing when inside Notification page */}
                        <div className='click-blocker' onClick={() => { }}></div>
                        {data.expirationDate &&
                          <Tooltip title={!checkExpired(data.expirationDate) ? `Edit ${data.isScheduled ? "Scheduled Dates" : "Expiration Date"}` : ''} arrow placement="top-start" >
                            <div >
                              <ExpirationChip
                                expired={checkExpired(data.expirationDate)}
                                scheduled={data.isScheduled}
                                promoted={data.isPromoted}
                                date={data.expirationDate}
                                onClick={!checkExpired(data.expirationDate) ? (e) => openDialog(e, data, "editDates") : null}
                              />
                            </div>
                          </Tooltip>
                        }
                      </TableCell>

                      {/* PUSH Column */}
                      <TableCell style={{ width: 50, justifyItems: 'center', textAlign: 'center' }}>
                        {data.isPush ? <div style={{ borderRadius: "100px", backgroundColor: "#EB0A1E", width: 25, height: 25 }}><NotificationIcon style={{ color: "#ffffff", marginTop: 2 }} fontSize="small" /></div> : null}
                      </TableCell>

                      {/* EDIT Column */}
                      <TableCell style={{ textAlign: 'center' }}>
                        <IconButton
                          color="primary"
                          style={(data.isPromoted || data.isArchived) ? { display: 'none' } : {}}
                          href={`/notifications/edit?id=${data.id}`}
                        ><EditIcon /></IconButton>
                      </TableCell>

                      {/* RECIPIENTS Column */}
                      <TableCell style={{ textAlign: 'center' }}>
                        <IconButton
                          style={(data.isPromoted || data.isArchived) ? { display: 'none' } : {}}
                          onClick={(e) => openDialog(e, data, 'editRecipients')}
                        ><PersonIcon style={{ color: '#808080' }} /></IconButton>
                      </TableCell>

                      {/* PROMOTE Column */}
                      <TableCell style={{ textAlign: 'center' }}>
                        <IconButton
                          style={(data.isPromoted || data.isArchived) ? { display: 'none' } : {}}
                          onClick={(e) => openDialog(e, data, "promote")}
                        ><PublishIcon style={{ color: '#7D4285' }} /></IconButton>
                      </TableCell>

                      {/* ARCHIVE Column */}
                      <TableCell style={{ textAlign: 'center' }}>
                        <IconButton
                          style={data.isArchived || checkExpired(data.expirationDate) ? { display: 'none' } : {}}
                          onClick={(e) => openDialog(e, data, "archive")}
                        ><ArchiveIcon style={{ color: '#58595B' }} /></IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (81) * emptyRows, }} >
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    <Snackbar
      open={openToast}
      autoHideDuration={3000} // Toast disappears after 3 seconds
      onClose={handleCloseToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert onClose={handleCloseToast} severity="success">
        ID copied to clipboard
      </Alert>
    </Snackbar>
    {getActivityDialog()}
  </>;
}

export default ActivityTable;

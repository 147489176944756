import React, { useEffect, useState } from "react";
import { IconButton, ListItem, ListItemText, TextField, Button, Tooltip } from '@material-ui/core';
import { Delete, Edit, CheckCircleOutline, InsertPhoto } from '@material-ui/icons';
import FileUploader from "../../components/FileUploader";
import config from "../../config";

const VehicleListItem = ( {data, deleteFunction, editFunction, validateFunction} ) => {
    const [editing, setEditing] = useState(false);
    const [year, setYear] = useState(data.modelYear);
    const [model, setModel] = useState(data.model);
    const [href, setHref] = useState(data.href);
    const [image, setImage] = useState(data.image);
    const [imageSrc, setImageSrc] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const src = image !== undefined ? URL.createObjectURL(image) : `${getEnvironmentUrl()}${data.rotatedImgSrc}`;
        setImageSrc(src);
    }, [image]);

    const addImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    const handleEdit = () => {
        const errors = validateFunction(year, model, href);

        if (Object.keys(errors).length) {
            setErrors(errors);
        } else {
            const vehicleItem = {
                modelYear: year,
                model: model,
                href: href,
                rotatedImgSrc: data.rotatedImgSrc,
            };

            if (image !== undefined) {
                vehicleItem.image = image;
                vehicleItem.rotatedImgSrc = `profile/upcoming/${Date.now()}-${image.name}`;
            }

            editFunction(vehicleItem);
            setEditing(false);
            setErrors({});
        }
    };

    const getEnvironmentUrl = () => {
        const sourceEnv = config.environments.sourceEnvironment;
        const domainPrefix = sourceEnv === "dev" ? sourceEnv + ".nonprod" : sourceEnv;
        return `https://${domainPrefix}.engage.toyota.com/static/images/jellybeans/`;
    };

    return (
        <ListItem disableGutters className="list-item">
          <>
            {editing ? 
                <>
                    <TextField
                        label={'Year'}
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        placeholder={`Ex. ${new Date().getFullYear() + 1}`}
                        variant="outlined"
                        size="small"
                        className="input-field year"
                        error={errors['year']}
                        helperText={errors['year'] || ''}
                    />
                    <TextField
                        label={'Model'}
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        placeholder={`Ex. Camry`}
                        variant="outlined"
                        size="small"
                        className="input-field model"
                        error={errors['model']}
                        helperText={errors['model'] || ''}
                    />
                    <TextField
                        label={'URL'}
                        value={href}
                        onChange={(e) => setHref(e.target.value)}
                        placeholder={`Ex. /articles/camry_overview`}
                        variant="outlined"
                        size="small"
                        className="input-field url"
                        error={errors['href']}
                        helperText={errors['href'] || ''}
                    />
                    <div className='file-uploader-wrapper'>
                        <FileUploader title={<InsertPhoto />} addFile={addImage} />
                    </div>
                    <Button onClick={() => handleEdit()} variant="contained" className="save-button">
                        <CheckCircleOutline />
                    </Button>
                </>
            :
                <>
                    <div className="info-container">
                        <ListItemText primary={`${data.modelYear} ${data.model}`} secondary={data.href ? `URL: ${data.href}` : 'No URL added'} className="list-item-text" />
                        <img className="vehicle-image" key={`${data.model}-${data.modelYear}`}
                            src={imageSrc}
                        />
                    </div>
                    <div className="button-container">
                        <Tooltip title='Edit'>
                            <IconButton onClick={() => setEditing(true)} className="edit-icon">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete'>
                            <IconButton onClick={deleteFunction} className="delete-icon">
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </div>
                </>
            }
            

          </>
        </ListItem>
    );
};

export default VehicleListItem;
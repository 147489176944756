import Axios from 'axios';

import { getToken } from '../auth';
import config from '../config';

// Create an reusable Axios instance
const apiClient = Axios.create({
  baseURL: config.apiGateway.ENGAGE_API_URL, // Set the base API URL
});

// Request interceptor to attach token to every request
apiClient.interceptors.request.use(
  async (requestConfig) => {
    // Currently we are making the token request
    const token = await getToken();
    requestConfig.headers.Authorization = `Bearer ${token}`;
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle errors
apiClient.interceptors.response.use(
  (response) => response, // (returning the response if successful)
  (error) => {
    console.error("API Error: ", error);
    return Promise.reject(error);
  }
);

///////////////////////////
// Engage API Services //
///////////////////////////
export function getCustomEngageApiPath(path) {
  return apiClient.get(path);
}

export function postCustomEngageApiPath(path, data) {
  return apiClient.post(path, data);
}

// Example Use:
export function getVideoAlias(id) {
  return apiClient.get(`/videos/alias/${id}`);
}

import { useEffect, useState } from "react";
import MetricGraph from '../../../components/MetricGraph';
import { getPageViews } from '../../../services/analyticsDashboardService';
import { addDays, differenceInDays, format, parseISO, isBefore, endOfDay, startOfDay } from "date-fns";
const DATE_FORMAT = "MM/dd/yyyy";


const PageViewsComponent = () => {

  const [searchValue, setSearchValue] = useState('/home');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [datePickerStartValue, setDatePickerStartValue] = useState(null);
  const [datePickerEndValue, setDatePickerEndValue] = useState(null);
  const [graphData, setGraphData] = useState([]);

  // Debounce Search
  useEffect(() => {
    const debouncer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue]);

  // Fetch and Set Graph on Search
  useEffect(() => {
    if (debouncedSearchValue) {
      const pagePath = getPagePath(debouncedSearchValue);
      fetchAndFormatGraphData(pagePath, datePickerStartValue, datePickerEndValue);
    }
  }, [debouncedSearchValue, datePickerStartValue, datePickerEndValue]);

  // Functions
  function getPagePath(input) {
    try {
      // If input starts with '/' (relative path), treat it as a path directly
      if (input.startsWith('/')) {
        return input;
      }

      // Otherwise, assume it's a full URL and parse it
      const parsedUrl = new URL(input);
      return parsedUrl.pathname; // Extract and return the pathname
    } catch (error) {
      console.error("Invalid input:", error);
      return null;
    }
  }

  const fetchAndFormatGraphData = async (pagePath, datePickerStartValue, datePickerEndValue) => {
    try {
      // Fetch from API, convert Date Picker state to ISO Strings
      const pageViewsData = await getPageViews({
        pagePath: pagePath,
        startDate: datePickerStartValue && startOfDay(datePickerStartValue).toISOString(),
        endDate: datePickerEndValue && endOfDay(datePickerEndValue).toISOString(),
      });
      console.log(pageViewsData);

      // Date Data from RDS is in String format, convert to Date()
      const pageViewsDateData = convertDataStringsToDateObjects(pageViewsData);
      console.log({ pageViewsDateData });

      // Transform Data for Graph Component
      const formattedGraphData = processDateDataForGraph(pageViewsDateData);
      console.log({ formattedGraphData });
      setGraphData(formattedGraphData);

    } catch (error) {
      console.log(error.message);
      setGraphData([]);
    }
  };

  // Time Stamp format from RDS, needs manual conversion to ISO String for parseISO
  function convertDataStringsToDateObjects(pageViewsData) {
    return pageViewsData.map(pageView => ({
      "datetime": parseISO(pageView["datetime"].replace(" ", "T") + "Z")
    }));
  }

  const processDateDataForGraph = (pageViewsData) => {
    // Generate Labels (X Axis)
    const { startDate, endDate } = getDateRange(pageViewsData);
    console.log({ startDate, endDate });
    const labels = generateLabels(startDate, endDate);
    console.log({ labels });

    // Group Page Views into Dates (Y values)
    const pageViewsMap = mapPageViewsByDate(pageViewsData);
    console.log({ pageViewsMap });

    // Convert to Graph Data Format
    const formattedGraphData = graphDataFromMap(pageViewsMap, labels);
    console.log({ formattedGraphData });

    return formattedGraphData;
  };

  // Get Start and End dates from Date Picker or defaults (oldest page view and current date)
  // Return as Date Objects set at start of day and end of day
  const getDateRange = (pageViews) => {
    const startDate = datePickerStartValue || getOldestPageViewDate(pageViews);
    const endDate = datePickerEndValue || new Date();
    return { startDate: startOfDay(startDate), endDate: endOfDay(endDate) };
  };

  // Find oldest page view via reducer, return as date object
  const getOldestPageViewDate = (pageViews) => {
    const oldestPageView = pageViews.reduce((oldest, pageView) => {
      return isBefore(pageView["datetime"], oldest) ? pageView : oldest;
    }, pageViews[0]["datetime"]);

    return oldestPageView;
  };

  // Create Label Array that fills in each day between start and end
  const generateLabels = (startDate, endDate) => {
    const daysDiff = differenceInDays(endDate, startDate);
    console.log({ daysDiff });
    return Array.from({ length: daysDiff + 1 }, (_, i) =>
      format(addDays(startDate, i), DATE_FORMAT)
    );
  };

  // Create an object (map) that has dates are the keys and visitor counts are the values
  const mapPageViewsByDate = (pageViews) => {
    return pageViews.reduce((acc, pageView) => {
      const pageViewDate = format(pageView["datetime"], DATE_FORMAT);
      acc[pageViewDate] = (acc[pageViewDate] || 0) + 1;
      return acc;
    }, {});
  };

  // Convert map into list for Graph Component consumption
  const graphDataFromMap = (pageViewsByDate, labels) => {
    const map = labels.map(label => ({
      time: label,
      visitors: pageViewsByDate[label] || 0
    }));
    return map;
  };


  return (
    <MetricGraph
      title={`Page Views: ${debouncedSearchValue || "(search via url)"}`}
      graphProps={{
        show: true,
        xDataKey: "time",
        lineDataKey: "visitors",
        xAxisLabel: "Time",
        yAxisLabel: "Page Views",
        lineColor: "#4DAA91",
        lineType: "monotone",
        height: 300,
        data: graphData,
      }}
      searchProps={{
        show: true,
        label: "Page URL",
        onChange: setSearchValue,
        value: searchValue,
      }}
      datePickersProps={[
        {
          show: true,
          label: "Start Date",
          onChange: setDatePickerStartValue,
          value: datePickerStartValue,
          format: "MM/dd/yyyy",
        },
        {
          show: true,
          label: "End Date",
          onChange: setDatePickerEndValue,
          value: datePickerEndValue,
          format: "MM/dd/yyyy",
        }
      ]}
    />
  );
};

export default PageViewsComponent;

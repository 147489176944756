import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { promoteItem } from '../../services/mcApiService.js';
import '../../App.css';
import NotificationDialog from '../../components/NotificationDialog/index.js';
import { getEnvironments } from '../../utils';

export default class PromoteArticleComponent extends React.Component {

    constructor(props) {
        super(props);
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.destinationEnvironment = getEnvironments().destinationEnvironment;
        this.state = {
            articleUrl: "",
            articleId: "",
            isArticleUrlEnglish: true,
            isArticleUrlSpanish: false,
            spinnerOn: false
        };
        this.updateArticleUrl = this.updateArticleUrl.bind(this);
        this.changeArticleUrlLanguage = this.changeArticleUrlLanguage.bind(this);
        this.promoteArticle = this.promoteArticle.bind(this);
        this.callLambda = this.callLambda.bind(this);
    }

    handleOpen = () => {
        if (!this.state.articleUrl) {
            alert("Please fill in all required fields");
        } else {
            this.setState({ open: true });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    updateArticleUrl(newUrl) {
        this.setState({
            articleUrl: newUrl.target.value.trim()
        })
    }
    changeArticleUrlLanguage(event) {
        this.setState({
            isArticleUrlEnglish: !this.state.isArticleUrlEnglish,
            isArticleUrlSpanish: !this.state.isArticleUrlSpanish
        });
    }

    startSpinner() {
        this.setState({
            open: false,
            spinnerOn: true
        });
    }

    stopSpinner() {
        this.setState({
            spinnerOn: false
        });
    }

    formatArticleUrl(articleUrl) {
        return articleUrl.split("engage.toyota.com/articles/")[1]
    }

    async promoteArticle () {
        if (this.state.articleUrl.indexOf(this.sourceEnvironment) > -1) {
            try {
                this.startSpinner();
                await this.callLambda();
                this.stopSpinner();
                this.notificationDialog.handleOpen("Success: Promoted article " + this.state.articleUrl);
                let higherEnv = this.destinationEnvironment === "test" ? this.destinationEnvironment + ".nonprod." : "";
                window.open("https://" + higherEnv + "engage.toyota.com/articles/" + this.formatArticleUrl(this.state.articleUrl));
            }
            catch (err) {
                this.stopSpinner();
                console.log(err);
                this.notificationDialog.handleOpen("Error promoting article: " + err.toString());
            }

        } else {
            this.notificationDialog.handleOpen("This is not a " + this.sourceEnvironment + "URL. Please cancel and correct the URL");
        }
    }

    callLambda() {
        return new Promise((resolve, reject) => {

            let urlArray = this.state.articleUrl.split('/');

            let body = {
                template: 'article',
                fromEnv: this.sourceEnvironment,
                toEnv: this.destinationEnvironment,
                language: this.state.isArticleUrlSpanish ? 'es' : 'en',
                articleId: urlArray.slice(-1)[0]
            }

            promoteItem(body)
                .then((response) => {
                    if (response.errorMessage) {
                        reject(response);
                    }
                    else {
                        console.log("Success: Article Promoted");
                        resolve(null);
                    }
                })
                .catch((error) => {
                    console.log("Exception " + error);
                    this.setState({
                        createdArticle: true,
                        createdArticleUrl: error.toString(),
                        creatingArticle: false
                    });
                    reject("error");
                });
        });
    }

    render() {
        const actions = [
            <Button
                color='primary'
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                color='primary'
                keyboardFocused={true}
                onClick={this.promoteArticle}
            >Promote</Button>,
        ];

        return (
            <div>
                <div className="Find-Article">
                <h1 className="App-title">Find Article by URL</h1>
                    <TextField
                        className="Article-URL"
                        hintText="Enter the Article URL"
                        floatingLabelText="Article URL"
                        errorText={this.state.articleUrlError}
                        onChange={this.updateArticleUrl}
                        rows={1}
                        fullWidth={true}
                        required={true}
                    /> <br />
                    <div className="Article-Language">
                        <Button variant='contained' color={this.state.isArticleUrlEnglish ? 'primary' : 'default'} onClick={this.changeArticleUrlLanguage}>English</Button>
                        <Button variant='contained' color={this.state.isArticleUrlSpanish ? 'primary' : 'default'} onClick={this.changeArticleUrlLanguage}>Spanish</Button>
                    </div>
                    <br />
                    <br />
                    <Button variant='contained' color='secondary' disabled={this.state.spinnerOn} onClick={this.handleOpen}>Promote Article</Button>
                    <br />
                    <br />
                    {this.state.spinnerOn ? <CircularProgress size={50} /> : null}
                </div>
                <Dialog
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    <DialogContent>
                        {"Are you sure you want to Promote this article? " + this.state.articleUrl}
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
                <NotificationDialog ref={dialogRef => (this.notificationDialog = dialogRef)} />
            </div >
        );
    }
}

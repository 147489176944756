import React, { useEffect, useState } from 'react';
import ActivityCard from '../../components/ActivityCard';
import { getNotifications } from '../../services/mcApiService';
import {
    Tooltip
} from '@material-ui/core';
import {
    Notifications as NotificationIcon,
    NotificationsActive as PushNotificationIcon,
    Error as AlertIcon,
} from '@material-ui/icons';
import CreatedChip from '../../components/ActivityTableComponents/createdChip';
import ScheduledChip from '../../components/ActivityTableComponents/scheduledChip';
import PromotedChip from '../../components/ActivityTableComponents/promotedChip';
import ExpirationChip from '../../components/ActivityTableComponents/expirationChip';

function NotificationActivityCard(props) {
    const [notificationData, setNotificationData] = useState(null);
    const [tableData, setTableData] = useState();
    const rowLimit = 5;

    useEffect(() => {
        // Get notification data from misson-control-api
        getNotifications().then(announcements => {
            setNotificationData(filterAndSort(announcements));
        });
    }, []);

    useEffect(() => {
        if (!notificationData) return;
        // Format notification data to table array of objects consumable by ActivityCard component
        const tableArray = [];
        for (let i = 0; i < Math.min(rowLimit, notificationData?.length); i++) {
            tableArray.push(renderRow(notificationData[i]));
        }
        setTableData(tableArray);
    }, [notificationData]);

    function filterAndSort(data) {
        // Copy array & remove archived notifications
        let filteredArray = [...data].filter(item => !item.isArchived);
        // Set sort date as promotedDate OR lastModifiedDate OR createdDate
        filteredArray = filteredArray.map(item => {
            let sortDate, status;
            if (isExpired(item.expirationDate)) {
                status = 'Expired';
                sortDate = item.promotedDate;
            } else if (item.isPromoted) {
                status = 'Promoted';
                sortDate = item.promotedDate;
            } else if (item.isScheduled) {
                status = 'Scheduled';
                sortDate = item.lastModifiedDate ? item.lastModifiedDate : item.createdDate;
            } else {
                status = 'Created';
                sortDate = item.lastModifiedDate ? item.lastModifiedDate : item.createdDate;
            }

            return { ...item, sortDate: sortDate, status: status };
        });
        filteredArray.sort((itemA, itemB) => compareDates(itemA.sortDate, itemB.sortDate));

        return filteredArray;
    }

    function compareDates(dateA, dateB) {
        const a = new Date(dateA);
        const b = new Date(dateB);
        if (a > b) return -1;
        if (b < a) return 1;
        return 0;
    }

    function renderRow(notification) {
        return {
            title: notification.title,
            titleTooltip: <><div>{notification.title}</div><div>{notification.message}</div></>,
            dateTime: getDateTime(notification),
            dateTimeTooltip: getDateTooltip(notification),
            alertIcon: getAlertIcon(notification),
            pushIcon: getPushIcon(notification),
            status: getStatusChip(notification),
            statusTooltip: getStatusTooltip(notification),
        };
    }

    function getDateTime(notification) {
        switch (notification.status) {
            case 'Expired': return formatDate(notification.promotedDate);
            case 'Promoted': return formatDate(notification.promotedDate);
            case 'Scheduled': return formatDate(notification.lastModifiedDate || notification.createdDate);
            case 'Created': return formatDate(notification.lastModifiedDate || notification.createdDate);
        }
    }

    function formatDate(dateTime) {
        return new Date(dateTime).toLocaleString('en-US', { timeZone: "America/Chicago", dateStyle: 'short', timeStyle: 'short' });
    }

    function getDateTooltip(notification) {
        switch (notification.status) {
            case 'Expired': return `Promoted on ${formatDate(notification.promotedDate)}`;
            case 'Promoted': return `Promoted on ${formatDate(notification.promotedDate)}`;
            case 'Scheduled':
                return notification.lastModifiedDate ? `Last Modified on ${formatDate(notification.lastModifiedDate)}` : `Created on ${formatDate(notification.createdDate)}`;
            case 'Created':
                return notification.lastModifiedDate ? `Last Modified on ${formatDate(notification.lastModifiedDate)}` : `Created on ${formatDate(notification.createdDate)}`;
        }
    }

    function getAlertIcon(notification) {
        if (notification.category === 'Outage Alert') {
            return <Tooltip title={'Outage Alert '} >
                <AlertIcon style={{ color: '#EB0A1E' }} />
            </Tooltip>;
        } else {
            return ' ';
        }
    }

    function getPushIcon(notification) {
        if (notification.isPush) {
            return <Tooltip title={'Push Notification '} >
                <PushNotificationIcon style={{ color: '#EB0A1E' }} />
            </Tooltip>;
        } else {
            return ' ';
        }
    }

    function getStatusChip(notification) {
        switch (notification.status) {
            case 'Expired': return <ExpirationChip expired={true} />;
            case 'Promoted': return <PromotedChip />;
            case 'Scheduled': return <ScheduledChip date={notification.scheduledDate} />;
            case 'Created': return <CreatedChip />;
        }
    }

    function getStatusTooltip(notification) {
        switch (notification.status) {
            case 'Expired': return `Expired on ${formatDate(notification.expirationDate)}`;
            case 'Promoted': return `Promoted on ${formatDate(notification.promotedDate)}`;
            case 'Scheduled': return `Scheduled for ${formatDate(notification.scheduledDate)}`;
            case 'Created': return `Created on ${formatDate(notification.createdDate)}`;
        }
    }

    function isExpired(expirationDate) {
        if (!expirationDate) return false;
        const expiredDate = new Date(expirationDate);
        const now = new Date();
        return expiredDate <= now;
    }

    return (
        <ActivityCard
            titleIcon={<NotificationIcon />}
            title='Latest Notification Activity'
            tableData={tableData}
            variant='activity'
            seeMore='/notifications/activity'
            allowAccess={props.allowAccess}
        />
    );
}

export default NotificationActivityCard;
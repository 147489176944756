import { useEffect, useState, useMemo } from "react";
import { format, parseISO, isAfter, isBefore, startOfDay, startOfWeek, startOfMonth, startOfYear, subYears  } from "date-fns";

// API Service
import { getUsersMetadata } from "../../../services/analyticsDashboardService";
import { formatDate } from "../utils";

// Reusable components
import MetricGraph from "../../../components/MetricGraph";

const usersDropdownCategories = ["Day", "Week", "Month", "Year"];
const DATE_FORMAT = "MM/dd/yyyy";

const ActiveUsersComponent = () => {
  // data
  const [userGraphResults, setUserGraphResults] = useState([])

  // MetricGraph Component States
  const [userGranularity, setUserGranularity] = useState("");

  //datepicker
  const [datePickerUserStartValue, setDatePickerUserStartValue] = useState(null);
  const [datePickerUserEndValue, setDatePickerUserEndValue] = useState(null);
  

  useEffect(async () => {
    const data = await fetchActiveUsersMetadata();
    setUserGraphResults(data)
  }, [])

   // get 1 year
  const fetchActiveUsersMetadata = async () => {
    return getUsersMetadata({
      startDate: formatDate(subYears(new Date(), 1)),
      endDate: formatDate(new Date())
    });
  };

  const generateUserGraphData = (data, granularity, startDate, endDate) => {
    
    if (!data.length) {
      return [];
    }

    // Parse and filter data based on the date range
    const parsedData = data?.map(item => ({
      ...item,
      datetime: parseISO(item.datetime),
    })).filter(item => 
      (!startDate || isAfter(item.datetime, startDate)) &&
      (!endDate || isBefore(item.datetime, endDate))
    );
  
    // Remove duplicate user entries
    const uniqueData = _.uniqBy(parsedData, 'user_id');
  
    // Group data based on the specified granularity
    const groupedData = _.groupBy(uniqueData, item => {
      switch (granularity.toLowerCase()) {
        case 'day':
          return format(startOfDay(item.datetime), 'yyyy-MM-dd');
        case 'week':
          return format(startOfWeek(item.datetime), 'yyyy-ww');
        case 'month':
          return format(startOfMonth(item.datetime), 'yyyy-MM');
        case 'year':
          return format(startOfYear(item.datetime), 'yyyy');
        default:
          return format(startOfDay(item.datetime), 'yyyy-MM-dd');
      }
    });
  
    // Format data for Recharts
    return Object.keys(groupedData).map(key => ({
      label: key,
      amount: groupedData[key].length,
    }));
  };

  const userGraphData = useMemo(() => generateUserGraphData(userGraphResults, userGranularity, datePickerUserStartValue, datePickerUserEndValue), [userGraphResults, userGranularity, datePickerUserStartValue, datePickerUserEndValue]);

  return (

      <MetricGraph
        title="Active Users Analytics"
        graphProps={{
          domain: ['dataMin - 1', 'dataMax + 1'],
          type:"area",
          show: true,
          xDataKey: "label",
          lineDataKey: "amount",
          lineColor: "#57C2D8",
          lineType: "monotone",
          xAxisLabel: "Period",
          yAxisLabel: "Amount",
          height: 300,
          data: userGraphData,
        }}
        dropdownProps={{
          show: true,
          label: "Granularity",
          onChange: setUserGranularity,
          value: userGranularity,
          items: usersDropdownCategories,
        }}
        datePickersProps={[
          {
            show: true,
            label: "Start Date",
            onChange: setDatePickerUserStartValue,
            value: datePickerUserStartValue,
            format: DATE_FORMAT,
          },
          {
            show: true,
            label: "End Date",
            onChange: setDatePickerUserEndValue,
            value: datePickerUserEndValue,
            format: DATE_FORMAT,
          },
        ]}
      />
  );
};

export default ActiveUsersComponent;

import React, { useMemo } from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import "./upcomingVehiclesPromoteMenu.css";
import config from "../../config";

const PromoteVehicleListItem = ( {data } ) => {

    const getEnvironmentUrl = () => {
        const sourceEnv = config.environments.sourceEnvironment;
        const domainPrefix = sourceEnv === "dev" ? sourceEnv + ".nonprod" : sourceEnv;
        return `https://${domainPrefix}.engage.toyota.com/static/images/jellybeans/`;
    };
    
    return (
        <ListItem disableGutters className="list-item">
            <div className="information-container">
                <ListItemText primary={`${data.modelYear} ${data.model}`} secondary={data.href ? `URL: ${data.href}` : 'No URL added'} className="list-item-text" />
                <img className="vehicle-image" key={`${data.model}-${data.modelYear}`} src={getEnvironmentUrl() + data.rotatedImgSrc}/>
            </div>
        </ListItem>
    );
};

export default PromoteVehicleListItem;
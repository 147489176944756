import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    addMinutes,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    format,
    compareAsc
} from 'date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DialogV2 from '../../components/DialogV2'
import DateTimePickerDialog from '../../components/DateTimePickerDialog'
import { getEnvironments } from '../../utils';
import { deleteScheduledPromotion, scheduleNotification } from '../../services/mcApiService';

// MATERIAL-UI COMPONENTS
import {
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';

import {
  Alert
} from '@material-ui/lab';

export default function EditDatesDialog(props) {
    const [now, setNow] = useState();
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editMethod, setEditMethod] = useState(props.notification.isScheduled ? "Scheduled" : props.isActive ? "Expiration" : null);

    const category = props.notification.category == "Outage Alert" ? "Outage Alert" : "Announcement";
    const timeZone = 'CST';

    useEffect(() => {
        const currentDateTime = new Date();
        setNow(currentDateTime);
        if (editMethod == "Expiration") {
            setStartDateTime(currentDateTime);
            setEndDateTime(new Date(props.notification.expirationDate));
        } else if (editMethod == "Scheduled") {
            setStartDateTime(new Date(props.notification.scheduledDate));
            setEndDateTime(new Date(props.notification.expirationDate));
        }
    }, [])

    useEffect(() => {
        // compare dates for invalid values
        setDisable((compareAsc(endDateTime, startDateTime) != 1) || (compareAsc(startDateTime, now) == -1));
    }, [startDateTime, endDateTime])

    function editDates() {
        setLoading(true);
        const scheduleNotificationData = {
            id: props.notification.id,
            scheduledDate: startDateTime.toISOString(),
            expirationDate: endDateTime.toISOString()
        };
        scheduleNotification(scheduleNotificationData).then(() => {
            setLoading(false);
            props.closeDialog(true, "Notification dates updated successfully!");
        }).catch((err) => {
            setLoading(false);
            props.closeDialog(false, "Notification dates failed to update.");
        })
    }

    function handleDeleteScheduledPromotion() {
        setLoading(true);
        deleteScheduledPromotion(props.notification.id).then(() => {
            setLoading(false);
            props.closeDialog(true, "Scheduled promotion deleted successfully!");
        })
        .catch((err) => {
            setLoading(false);
            props.closeDialog(false, "Scheduled promotion failed to delete.");
        })
    }

    const getTitle = () => {
        if (editMethod == "Scheduled") {
            return `Edit Scheduled Dates for ${getEnvironments().formattedDestinationEnvironment}`;
        } else if (editMethod == "Expiration") {
            return `Edit Expiration Date in ${getEnvironments().formattedDestinationEnvironment}`;
        } else if (editMethod == "Delete Scheduled") {
            return `Delete Scheduled Promotion to ${getEnvironments().formattedDestinationEnvironment}`
        } else return "Edit Dates"
    }

    const getDateTimePickers = () => {
        if (editMethod == "Expiration") {
            return <DateTimePickerDialog label="Expiration Date Time" date={endDateTime} setDate={setEndDateTime} minDate={addMinutes(startDateTime, 1)} />
        } else if (editMethod == "Scheduled") {
            return <>
                <DateTimePickerDialog label="Scheduled Date Time" date={startDateTime} setDate={setStartDateTime} minDate={now} />
                <DateTimePickerDialog label="Expiration Date Time" date={endDateTime} setDate={setEndDateTime} minDate={addMinutes(startDateTime, 1)} />
            </>
        }
    }

    const getTimeDifference = () => {
        let days = differenceInDays(endDateTime, startDateTime);
        let hours = differenceInHours(endDateTime, startDateTime);
        let minutes = differenceInMinutes(endDateTime, startDateTime);

        if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""}`
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? "s" : ""}`
        } else {
            return `${minutes} minute${minutes > 1 ? "s" : ""}`
        }
    }

    const LiveOnEngageAlert = (props) => (
        <Alert severity="info">
            This {category} will be live on Engage for <strong>{getTimeDifference()}</strong> from <strong>{props.start}</strong> until <strong>{props.end}</strong>.
        </Alert>
    )

    const NewExpirationAlert = (props) => (
        <Alert severity="info" >
            This {category} will now expire on <strong>{props.end}</strong>.
        </Alert>
    )

    const PushNotificationAlert = (props) => (
        <Alert severity="info">
            This {category} will be sent as an Engage App push notification {props.start != "now" && "on "}<strong>{props.start}</strong>.
        </Alert>
    )

    const DatesErrorAlert = () => (
        <Alert severity="error">
            The chosen values are invalid. Please select future Date Times to proceed.
        </Alert>
    )

    const DeleteScheduledAlert = (props) => (
        <Alert severity="error">
            This {category} is scheduled to be promoted on <strong>{props.start}</strong>. Are you sure you want to delete this scheduled promotion?.
        </Alert>
    )

    const getAlertMessages = () => {
        const alerts = [];
        if (disable) {
            return <DatesErrorAlert />
        }
        if (editMethod == "Expiration") {
            alerts.push(<NewExpirationAlert end={`${format(endDateTime, "PPPPp")} ${timeZone}`} />);
        } else if (editMethod == "Scheduled") {
            alerts.push(<LiveOnEngageAlert start={`${format(startDateTime, "PPPPp")} ${timeZone}`} end={`${format(endDateTime, "PPPPp")} ${timeZone}`} />);
            if(props.notification.isPush) {
                alerts.push(<PushNotificationAlert start={`${format(startDateTime, "PPPPp")} ${timeZone}`} />);
            }
        } else if (editMethod == "Delete Scheduled") {
            alerts.push(<DeleteScheduledAlert start={`${format(new Date(props.notification.scheduledDate), "PPPPp")} ${timeZone}`} />);
        }
        return alerts;
    }

    const getContent = () => (
        <Grid container direction="column" style={{gap: "20px"}}>
            {editMethod != "Delete Scheduled" && <Grid container direction="row" justifyContent="space-evenly">
                {getDateTimePickers()}
            </Grid>}
            <Grid container direction="column" justifyContent="space-evenly" style={{gap: "10px"}}>
                {getAlertMessages()}
            </Grid>
        </Grid>
    )

    const getButtons = () => {
        if (editMethod == "Delete Scheduled") {
            return (<>
                <Button
                    onClick={()=>setEditMethod("Scheduled")}
                    color="primary"
                    variant="text"
                    disabled={loading}
                >Back</Button>
                <Button
                    onClick={()=>handleDeleteScheduledPromotion()}
                    style={{ backgroundColor: '#EB0A1E', color: '#FFFFFF' }}
                    variant="contained"
                    disabled={props.disabled || loading}
                    startIcon={loading ? <CircularProgress size="20px" color="inherit" /> : null}
                >Delete</Button>
            </>)
        } else {
            return (<>
                <Button
                    onClick={()=>props.closeDialog()}
                    color="primary"
                    variant="text"
                    disabled={loading}
                >Cancel</Button>
                <Button
                    onClick={()=>editDates()}
                    color="primary"
                    variant="contained"
                    disabled={disable || loading}
                    startIcon={loading ? <CircularProgress size="20px" color="inherit" /> : null}
                >Save Changes</Button>
                {editMethod == "Scheduled" && <Button
                    onClick={()=>setEditMethod("Delete Scheduled")}
                    style={{ color: '#EB0A1E', border: '1px solid #EB0A1E' }}
                    variant="outlined"
                    disabled={disable || loading}
                >Delete Scheduled Promotion</Button>}
            </>)
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DialogV2
                open={props.open}
                title={getTitle()}
                content={(startDateTime && endDateTime) && getContent()}
                actions={getButtons()}
            />
        </MuiPickersUtilsProvider>
    )
  }
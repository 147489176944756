import { isBefore } from 'date-fns';
import * as _ from 'lodash'

// this functions takes a date and return a string on format YYYY-MM-DD
export const formatDate = (date) => {
  return date.toISOString().split('T')[0];
}
// Get the oldest article date for the mtreics graph component
export const getOldestArticleDate = (articles) => {
  const oldestArticle = articles.reduce((oldest, article) => {
    const articleDate = new Date(article.created_date);

    return isBefore(articleDate, new Date(oldest.created_date)) ? article : oldest;
  }, articles[0]);
  return oldestArticle ? new Date(oldestArticle.created_date) : null;
}

export const getBoxesPeriodData = (metadata, dateKey = 'created_date') => {
  const allTime = metadata.length;
  const lastSixMonths = metadata.filter((item) => {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    return new Date(item.created_date) > sixMonthsAgo;
  }).length;
  const lastThirtyDays = metadata.filter((item) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return new Date(item.created_date) > thirtyDaysAgo;
  }).length

  return {
    allTime,
    lastSixMonths,
    lastThirtyDays,
  };
};

export const getActiveUsersPeriodData = (metadata) => {
  const lastYear = metadata.filter((item) => {
    const yearAgo = new Date();
    yearAgo.setMonth(yearAgo.getMonth() - 12);
    return new Date(item.datetime) > yearAgo;
  });
  const lastMonth = metadata.filter((item) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return new Date(item.datetime) > thirtyDaysAgo;
  });

  const lastWeek = metadata.filter((item) => {
    const lastWeekAgo = new Date();
    lastWeekAgo.setDate(lastWeekAgo.getDate() - 7);
    return new Date(item.datetime) > lastWeekAgo;
  });

  // unique values
  const lastYearUnique = _.uniqBy(lastYear, 'user_id').length
  const lastMonthUnique = _.uniqBy(lastMonth, 'user_id').length
  const lastWeekUnique = _.uniqBy(lastWeek, 'user_id').length
  

  return {
    lastYear: lastYearUnique,
    lastMonth: lastMonthUnique,
    lastWeek: lastWeekUnique,
  };
};

export const setMetadataPeriodData = (metadata, setState) => {
  setState((prevState) => {
    return {
      ...prevState,
      Metrics: [
        { metricName: "Last 30 days", metricValue: metadata.lastThirtyDays },
        { metricName: "Last 6 months", metricValue: metadata.lastSixMonths },
        { metricName: "All time total", metricValue: metadata.allTime },
      ],
    };
  });
};

export const setMetadataUsersPeriodData = (metadata, setState) => {
  setState((prevState) => {
    return {
      ...prevState,
      Metrics: [
        { metricName: "Last year", metricValue: metadata.lastYear },
        { metricName: "Last month", metricValue: metadata.lastMonth },
        { metricName: "Last week", metricValue: metadata.lastWeek },
      ],
    };
  });
};
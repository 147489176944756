import React, { useEffect, useState } from 'react';
import {
    Paper,
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Switch,
    Button,
    CircularProgress
} from '@material-ui/core';
import {
    CheckCircle as SaveIcon,
    Publish as PublishIcon,
    Close as CloseIcon,
    CloudUpload as CloudUploadIcon
} from '@material-ui/icons';
import { Skeleton, Alert } from '@material-ui/lab';
import { styled } from '@material-ui/styles';

import { getImageUrl, uploadNotificationImage } from '../../utils';
import DialogV2 from '../DialogV2';
import TextareaMention from '../TextareaMentions';

// EXPECTED PROPS
//   - notification
//         Object with 'title' string, 'message' string, 'category' string, and 'isPush' boolean
//         Used to load existing data into form (if editing a notification) and keep track of changes
//   - setNotification
//         Function to update the notification object
//   - save
//         Function to execute when clicking the save button
//   - saveLabel
//         String text to display on save button
//   - saveIcon
//         Boolean - display save icon if true (editing), otherwise default to Publish icon
//   - categories
//         Array of objects with 'label' string & 'iconPath' string

function NotificationForm(props) {
    const [showModal, setShowModal] = useState(false);
    const [imageFile, setImageFile] = useState(null);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        if (!props.notification) return;
        let notification = { ...props.notification };

        // If the title is blank, autofill the category name
        const categoryLabels = props.categories?.map(c => c.label);
        if (props.notification.title === '' || categoryLabels?.includes(props.notification.title)) {
            notification.title = props.notification.category;
        }

        // Map category to icon
        const selectedCategoryData = props.categories?.find(c => c.label === props.notification.category);
        if (selectedCategoryData) {
            notification.icon = selectedCategoryData.iconPath;
        }

        props.setNotification(notification);
    }, [props.notification?.category]);

    function handleInputChange(value, field) {
        const updatedData = { ...props.notification };
        updatedData[field] = value;
        props.setNotification(updatedData);
    }

    function handleSave() {
        if (props.notification.title === '' ||
            props.notification.message === '' ||
            props.notification.category === '') {
            setShowModal(true);
        } else {
            imageFile && uploadNotificationImage(imageFile);
            props.save(imageFile ? encodeURIComponent(imageFile.name) : '');
        }
    }

    function closeModal() {
        setShowModal(false);
    }

    const handleRemoveImage = (e) => {
        e.preventDefault();
        setImageFile(null);
    };

    const validateImageFile = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];

        if (file) {
            const maxSize = 500 * 1024; // 500 KB in bytes
            file.size > maxSize ? alert('File size exceeds 500KB. Please upload a smaller image.') : setImageFile(file);
        }

        fileInput.value = null;
    };

    if (!props.notification) return <Skeleton variant='rect' height={200} style={{ marginBottom: '25px' }} />;

    return <Box style={{ display: 'flex', flexDirection: 'column', gap: '24px', paddingBottom: '24px' }} >
        <Box style={{ display: 'flex', gap: '24px' }} >
            <Paper>
                <FormControl variant='outlined' style={{ width: '244px', textAlign: 'left' }} disabled={props.loading} >
                    <InputLabel id='category-select-label'>Category</InputLabel>
                    <Select
                        labelId='category-select-label'
                        id='category-select'
                        value={props.notification?.category}
                        onChange={(event) => handleInputChange(event.target.value, 'category')}
                        label='Category'
                    >
                        {props.categories?.map(category =>
                            <MenuItem key={category.label} value={category.label} >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={getImageUrl(category.iconPath)} style={{ height: '24px', marginRight: '8px' }} />
                                    {category.label}
                                </div>
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Paper>
            <Paper style={{ width: '50%' }}>
                <TextField
                    variant='outlined'
                    fullWidth
                    label='Title'
                    value={props.notification?.title}
                    onChange={(event) => handleInputChange(event.target.value, 'title')}
                    inputProps={{ maxLength: 125 }}
                    disabled={props.loading}
                />
            </Paper>
            <Paper style={{ width: '50%' }} >
                <TextField
                    variant='outlined'
                    fullWidth
                    label='Path'
                    value={props.notification?.redirectPath}
                    onChange={(event) => handleInputChange(event.target.value, 'redirectPath')}
                    inputProps={{ maxLength: 125 }}
                    disabled={props.loading}
                />
            </Paper>
            <Button
                component="label"
                style={{ width: '30%' }}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'inline-block'
                    }}
                >
                    {imageFile?.name ? imageFile.name : 'Attach Image'}
                </span>
                <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(event) => { validateImageFile(event); }}
                />
                {imageFile && (<CloseIcon onClick={handleRemoveImage} style={{ marginLeft: '10px', cursor: 'pointer' }} />)}
            </Button>
        </Box>
        <Box style={{ display: 'flex', gap: '24px' }} >
            <Paper style={{ width: '100%' }} >
                <TextareaMention
                    value={props.notification?.message}
                    chipOptions={[
                        { id: "name", display: "User Name" },
                        { id: "region", display: "User Region" },
                    ]}
                    onChange={(newMessage) => handleInputChange(newMessage, 'message')}
                />
            </Paper>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', gap: '24px' }} >
            <FormControlLabel
                control={
                    <Switch
                        checked={props.notification?.isPush}
                        onChange={() => handleInputChange(!props.notification?.isPush, 'isPush')}
                        color='secondary'
                    />
                }
                label='App Push Notification'
                disabled={props.loading}
            />
            <Button
                variant='contained'
                style={{ backgroundColor: '#F8D76B', color: '#58595B' }}
                size='large'
                startIcon={props.loading ? <CircularProgress size="20px" color="inherit" /> : props.saveIcon ? <SaveIcon /> : <PublishIcon />}
                onClick={handleSave}
                disabled={props.loading}
            >
                {props.saveLabel}
            </Button>
        </Box>
        <DialogV2
            open={showModal}
            title={"Error Saving Notification"}
            content={<Alert severity="error">All notifications must have a <strong>Category</strong>, <strong>Title</strong> & <strong>Message</strong> - make sure none of these fields are blank and try again.</Alert>}
            actions={
                <Button
                    onClick={closeModal}
                    style={{ color: '#FFFFFF', backgroundColor: '#EB0A1E' }}
                    variant="contained"
                    autoFocus
                >Dismiss</Button>
            }
        />
    </Box >;
}

export default NotificationForm;
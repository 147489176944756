import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { deleteArticle } from '../../services/mcApiService';
import '../../App.css';
import { getEnvironments } from '../../utils';


export default class DeleteArticleComponent extends React.Component {

    constructor(props) {
        super(props);
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.destinationEnvironment = getEnvironments().destinationEnvironment;
        this.state = {
            articleUrl: "",
            isArticleUrlEnglish: true,
            isArticleUrlSpanish: false,
            deleteArticle: false,
            selectedEnvironment: this.sourceEnvironment,
            spinnerOn: false
        };
        this.updateArticleUrl = this.updateArticleUrl.bind(this);
        this.changeArticleUrlLanguage = this.changeArticleUrlLanguage.bind(this);
        this.deleteArticle = this.deleteArticle.bind(this);
        this.changeArticleEnvironment = this.changeArticleEnvironment.bind(this);
    }

    handleOpen = () => {
        if (!this.state.articleUrl) {
            alert("Please fill in all required fields");
        } else {
            this.setState({ open: true });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleRequestClose = () => {
        this.setState({
            deleteArticle: false,
        });
    };

    updateArticleUrl = function (newUrl) {
        this.setState({
            articleUrl: newUrl.target.value.trim()
        })
    }
    changeArticleUrlLanguage(event) {
        this.setState({
            isArticleUrlEnglish: !this.state.isArticleUrlEnglish,
            isArticleUrlSpanish: !this.state.isArticleUrlSpanish
        });
    }

    changeArticleEnvironment(event) {
        this.setState({
            selectedEnvironment: event.target.textContent.trim()
        });
    }

    async deleteArticle() {
        this.setState({
            open: false,
            spinnerOn: true
        });
        let resultMessage = await this.callLambda();
        this.setState({
            statusMessage: resultMessage,
            deleteArticle: true,
            spinnerOn: false,
        });
    }

    callLambda() {
        return new Promise((resolve, reject) => {
            let urlArray = this.state.articleUrl.split('/');

            let articleId = urlArray.slice(-1)[0];
            let language = this.state.isArticleUrlEnglish ? 'en' : 'es';
            let env = this.state.selectedEnvironment.toLowerCase();

            deleteArticle(articleId, language, env)
                .then(response => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        resolve('Error: No response from API');
                    }
                }).catch(error => {
                    resolve('Error occured: ' + error);
                });
        });
    }

    render() {
        const actions = [
            <Button
                color='primary'
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                color='primary'
                keyboardFocused={true}
                onClick={this.deleteArticle}
            >Delete</Button>,
        ];

        return (
            <div>
                <div className="Find-Article">
                    <h1 className="App-title">Find Article by URL</h1>
                    <TextField
                        className="Article-URL"
                        hintText="Enter the Article URL"
                        floatingLabelText="Article URL"
                        errorText={this.state.articleUrlError}
                        onChange={this.updateArticleUrl}
                        rows={1}
                        fullWidth={true}
                        required={true}
                        errorText={this.state.articleUrl ? "" : "This Field is Required"}
                    /> <br />
                    <div className="Article-Language">
                        <Button variant='contained' color={this.state.isArticleUrlEnglish ? 'primary' : 'default'} onClick={this.changeArticleUrlLanguage} >English</Button>
                        <Button variant='contained' color={this.state.isArticleUrlSpanish ? 'primary' : 'default'} onClick={this.changeArticleUrlLanguage} >Spanish</Button>
                    </div>
                    <div className="Article-Environment">
                        <Button variant='contained' color={this.state.selectedEnvironment === this.sourceEnvironment ? 'primary' : 'default'} onClick={this.changeArticleEnvironment} >{this.sourceEnvironment}</Button>
                        <Button variant='contained' color={this.state.selectedEnvironment === this.destinationEnvironment ? 'primary' : 'default'} onClick={this.changeArticleEnvironment}>{this.destinationEnvironment}</Button>
                    </div>
                </div>
                <br />
                <Button variant='contained' color='secondary' onClick={this.handleOpen} disabled={this.state.spinnerOn}>Delete Article</Button>
                <br />
                <br />
                {this.state.spinnerOn ? <CircularProgress size={50} /> : null}
                <Dialog
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    <DialogContent>
                        {"Are you sure you want to delete this article? " + this.state.articleUrl}
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={this.state.deleteArticle}
                    message={this.state.statusMessage}
                    autoHideDuration={6000}
                    onRequestClose={this.handleRequestClose}
                />
            </div >
        );
    }
}


import { Chip } from '@material-ui/core';
import { getImageUrl } from '../../utils';

const CategoryChip = (props) => {
    if (props.notification.category == "Outage Alert") {
        return <Chip label="OUTAGE ALERT" size="small" style={{ backgroundColor: '#EB0A1E', color: '#FFFFFF' }} />;
    } else {
        const iconPath = props.categories.find(category => category.label === props.notification.category)?.iconPath;
        return <div>
            <img src={iconPath ? getImageUrl(iconPath) : null} style={{ height: 20, maxWidth: 20, verticalAlign: 'text-top', marginTop: -2, paddingRight: 6 }} />
            <span style={props.notification.icon ? {} : { paddingLeft: 20 }}>{props.notification.category}</span>
        </div>;
    }
};

export default CategoryChip;

/* eslint-disable */
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import React from 'react';
import { getVehicleTrims, promoteItem } from '../../services/mcApiService.js';
import '../../App.css';
import NotificationDialog from '../../components/NotificationDialog/index.js';
import {
    getEnvironments,
    getTemplateTypes,
    getTemplateVersion,
    getUrlModelName,
    getVehicleYears,
    getVehicleModels
} from '../../utils';
export default class PromoteTemplateComponent extends React.Component {

    constructor(props) {
        super(props);
        this.destinationEnvironment = getEnvironments().destinationEnvironment;
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.state = {
            templateTypes: [],
            templateType: "",
            images: [],
            vehicleModel: "",
            vehicleModels: [],
            vehicleYear: "",
            vehicleYears: [],
            displayPublishButton: false,
            spinnerOn: false,
            vehicleTrim: '',
            vehicleTrims: [],
            vehicleTrimsDropdown: [],
            vehicleCompetitor: '',
            vehicleCompetitors: [],
            trimsLoaded: false,
        };
        this.updateTemplate = this.updateTemplate.bind(this);
        this.updateVehicle = this.updateVehicle.bind(this);
        this.updateCompetitor = this.updateCompetitor.bind(this);
        this.updateVehicle = this.updateVehicle.bind(this);
        this.updateVehicleDropdown = this.updateVehicleDropdown.bind(this);
        this.TemplateBody = this.TemplateBody.bind(this);
        this.getVehicleYears = getVehicleYears.bind(this);
        this.updateVehicleYear = this.updateVehicleYear.bind(this);
        this.promoteTemplate = this.promoteTemplate.bind(this);
        this.callLambda = this.callLambda.bind(this);
        this.updateVehicleTrim = this.updateVehicleTrim.bind(this);
        this.updateVehicleTrims = this.updateVehicleTrims.bind(this);
        this.getDropdownTrims = this.getDropdownTrims.bind(this);
        this.getDropdownCompetitors = this.getDropdownCompetitors.bind(this);
        this.promoteItem = promoteItem.bind(this);
        this.getTemplateTypes = getTemplateTypes.bind(this);
    }

    componentWillMount() {
        let templateTypes = this.getTemplateTypes();
        let vehicleYears = this.getVehicleYears();
        this.setState({
            templateTypes,
            vehicleYears
        });
    }

    async updateVehicleDropdown() {
        const vehicleModels = await getVehicleModels();
        this.setState({
            vehicleModels
        });
    }

    updateTemplate = function (event) {
        let templateType = event.target.value;
        if ([
                "Vehicle",
                "Competitive Advantages",
                "Edge (Legacy)",
                "Head to Head",
                "Head to Head v2",
                "Model Overview (MY21+)"
            ].includes(templateType) && !this.state.vehicleModels.length) {
            this.updateVehicleDropdown();
        }
        if (this.state.vehicleYear) {
            templateType = getTemplateVersion(templateType, this.state.vehicleYear);
        };
        this.setState({
            templateType: templateType
        });
    }

    updateVehicle = function (event) {
        const vehicleModel = event.target.value;
        this.setState({
            vehicleModel: vehicleModel
        }, () => {
            if (this.state.vehicleYear && (this.state.templateType === "Edge (Legacy)" || this.state.templateType === "Head to Head")) {
                getVehicleTrims(vehicleModel.toLowerCase(), this.state.vehicleYear, 'en')
                    .then(response => {
                        this.updateVehicleTrims(response);
                        this.setState({ trimsLoaded: true });
                    });
            }
        })
    }

    updateVehicleTrim = function (event) {
        const index = this.state.vehicleTrims.findIndex(trim => trim.gradeAndTrim === event.target.value);
        let dropdownCompetitors = [];
        if (index > -1) {
            dropdownCompetitors = this.getDropdownCompetitors(this.state.vehicleTrims[index].competitors);
        }
        this.setState({
            vehicleTrim: event.target.value,
            vehicleCompetitors: dropdownCompetitors,
            vehicleCompetitor: dropdownCompetitors[0]
        });
    }

    updateCompetitor = function (event) {
        this.setState({
            vehicleCompetitor: event.target.value
        });
    }

    updateVehicleYear = function (event, index, value) {
        const templateType = getTemplateVersion(this.state.templateType, event.target.value);
        this.setState({
            vehicleYear: event.target.value,
            templateType: templateType
        }, () => {
            if (this.state.templateType === "Edge (Legacy)" || this.state.templateType === "Head to Head" || this.state.templateType === "Head to Head v2") {
                getVehicleTrims(this.state.vehicleModel.toLowerCase(), event.target.value, 'en')
                    .then(response => {
                        this.updateVehicleTrims(response);
                        this.setState({ trimsLoaded: true });
                    });
            }
        })
    }

    updateVehicleTrims(response) {
        if (response.error) {
            console.error("Error occured: " + response.error);
            return response;
        } else {
            if (response.length > 0) {
                console.info('got data');
                let dropdownTrims = this.getDropdownTrims(response);
                let dropdownCompetitors = this.getDropdownCompetitors(response[0].competitors);
                this.setState({
                    vehicleTrimsDropdown: dropdownTrims,
                    vehicleTrims: response,
                    vehicleTrim: 0,
                    vehicleCompetitors: dropdownCompetitors
                })
            } else {
                this.setState({
                    vehicleTrim: '',
                    vehicleTrims: [],
                    vehicleTrimsDropdown: [],
                    vehicleCompetitor: '',
                    vehicleCompetitors: [],
                    vehicleModel: "",
                    vehicleYear: "",
                })
                this.notificationDialog.handleOpen("No Edge's were found for this vehicle!");
            }
        }
    }

    getDropdownTrims(vehicleModels) {
        let array = []
        vehicleModels.forEach(model => {
            array.push(model.gradeAndTrim);
        });
        return array;
    }

    getDropdownCompetitors(competitors) {
        let array = [];
        competitors.forEach(competitor => {
            if (Number(this.state.vehicleYear) < 2021) {
                array.push(competitor.name);
            } else {
                const comp = competitor.competitorVehicle;
                array.push(`${comp.year} ${comp.model}`)
            }
        });
        return array;
    }

    promoteTemplate() {
        this.startSpinner()
        this.callLambda();
    }

    startSpinner() {
        this.setState({
            spinnerOn: true
        });
    }

    stopSpinner() {
        this.setState({
            spinnerOn: false
        });
    }


    async callLambda() {
        let promoteData = await this.getPromoteData();
        promoteItem(promoteData.body)
            .then(response => {
                this.stopSpinner();
                if (response.status > 200) {
                    this.notificationDialog.handleOpen("Error promoting template. Please contact the dev team: " + response.data.toString());
                } else {
                    this.notificationDialog.handleOpen("Successfully Promoted to " + this.destinationEnvironment + "!");
                    window.open(promoteData.url);
                }
            })
            .catch(err => {
                this.notificationDialog.handleOpen("Error promoting template. Please contact the dev team: " + err.toString());
                this.stopSpinner();
            });
    }

    async getPromoteData() {
        let urlEnv = this.destinationEnvironment !== "prod" ? this.destinationEnvironment + "." : "";
        let url = 'https://' + urlEnv + 'engage.toyota.com/';
        let body = {};
        if (this.state.templateType === "App Page") {
            body = {
                "template": "appLanding",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "app";
        } else if (this.state.templateType === "Audio Multimedia") {
            body = {
                "template": "amm",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "amm";
        } else if (this.state.templateType === "Beyond Zero") {
            body = {
                "template": "beyondZero",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "hybrid";
        }
        else if (this.state.templateType === "Homepage v2") {
            body = {
                "template": "homepage_v2",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "home";
        } else if (this.state.templateType === "Homepage v3") {
            body = {
                "template": "homepage_v3",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "home";
        } else if (this.state.templateType === "EngageXP") {
            body = {
                "template": "engageXP",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "engageXP";
        } else if (this.state.templateType === "Feature Lookup") {
            body = {
                "template": "featureLookup",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "featureLookup";
        } else if (this.state.templateType === "Nitro Overview") {
            body = {
                "template": "nitroOverview",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "nitroOverview";
        } else if (this.state.templateType === "Towing & Payload") {
            body = {
                "template": "towingPayload",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "towingPayload";
        } else if (this.state.templateType === "Tournament") {
            body = {
                "template": "tournament",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "engage-xp/tournaments";
        }
        else if (this.state.templateType === "Login") {
            body = {
                "template": this.state.templateType.toLowerCase(),
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "login";
        } else if (this.state.templateType === "Recalls and Safety Campaign") {
            body = {
                "template": 'recalls',
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "recalls";
        } else if (this.state.templateType === "Service Connect") {
            body = {
                "template": "serviceConnect",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "serviceConnect";
        } else if (this.state.templateType === "Edge (Legacy)") {
            body = {
                "template": "edge",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en',
                "modelName": this.state.vehicleModel,
                "modelYear": this.state.vehicleYear.toLocaleLowerCase(),
                "gradeAndTrim": this.state.vehicleTrim,
                "competitor": this.state.vehicleCompetitor
            };
            url += "product/" +
                getUrlModelName(this.state.vehicleModel) + "/" +
                this.state.vehicleYear.toLocaleLowerCase() +
                "/competitiveAdvantages/headToHead?trim=" +
                this.state.vehicleTrim +
                "&competitor=" + this.state.vehicleCompetitor;
        }
        else if (this.state.templateType === "Head to Head") {
            body = {
                "template": "head to head",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en',
                "modelName": this.state.vehicleModel,
                "modelYear": this.state.vehicleYear.toLocaleLowerCase(),
                "gradeAndTrim": this.state.vehicleTrim,
                "competitor": this.state.vehicleCompetitor
            };
            url += "product/" +
                getUrlModelName(this.state.vehicleModel) + "/" +
                this.state.vehicleYear.toLocaleLowerCase() +
                "/competitiveAdvantages/edge?trim=" +
                this.state.vehicleTrim +
                "&competitor=" + this.state.vehicleCompetitor;
        }
        else if (this.state.templateType === "Head to Head v2") {
            body = {
                "template": "head to head v2",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en',
                "modelName": this.state.vehicleModel,
                "modelYear": this.state.vehicleYear.toLocaleLowerCase(),
                "gradeAndTrim": this.state.vehicleTrim,
                "competitor": this.state.vehicleCompetitor
            };
            url += "product/" +
                getUrlModelName(this.state.vehicleModel) + "/" +
                this.state.vehicleYear.toLocaleLowerCase() +
                "/competitiveAdvantages/edge?trim=" +
                this.state.vehicleTrim +
                "&competitor=" + this.state.vehicleCompetitor;
        }
        else if (this.state.templateType === "Competitive Advantages") {
            body = {
                "template": "caOverview",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en',
                "modelName": this.state.vehicleModel,
                "modelYear": this.state.vehicleYear.toLocaleLowerCase()
            };
            url += "product/" +
                getUrlModelName(this.state.vehicleModel) + "/" +
            this.state.vehicleYear.toLocaleLowerCase() +
                "/competitiveAdvantages/overview";
        } else if (this.state.templateType === "Certification") {
            body = {
                "template": "certification",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "certification";
        } else if (this.state.templateType === "Safety Landing") {
            body = {
                "template": "safetyLanding",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "safety";
        } else if (this.state.templateType === "Quick Start Guide") {
            body = {
                "template": "quickStartGuide",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url = 'https://' + urlEnv + 'explore.engage.toyota.com';
        } else if (this.state.templateType === "TCUV") {
            body = {
                "template": "tcuv",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "TCUV/certificationProgram";
        } else if (this.state.templateType === "TSS") {
            body = {
                "template": "tss",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "toyotaSafetySense/overview";
        } else if (this.state.templateType === 'Star Safety System') {
            body = {
                "template": "star safety system",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en'
            };
            url += "starSafetySystem/overview";
        } else if (this.state.templateType === "Model Overview (MY21+)") {
            body = {
                "template": "overview",
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": "en",
                "modelName": this.state.vehicleModel,
                "modelYear": this.state.vehicleYear.toLocaleLowerCase()
            };
            url += "product/" +
                getUrlModelName(this.state.vehicleModel) + "/" +
                this.state.vehicleYear.toLocaleLowerCase() +
                "/modelOverview";

        } else {
            body = {
                "template": this.state.templateType.toLowerCase(),
                "fromEnv": this.sourceEnvironment,
                "toEnv": this.destinationEnvironment,
                "language": 'en',
                "modelName": this.state.vehicleModel,
                "modelYear": this.state.vehicleYear.toLocaleLowerCase()
            };
            url += "product/" +
                getUrlModelName(this.state.vehicleModel) + "/" +
                this.state.vehicleYear.toLocaleLowerCase() +
                "/featuresAndSpecs";
        }

        return {
            url: url,
            body: body
        };
    }

    TemplateBody = function () {
        if (this.state.templateType === "Vehicle") {
            return (
                <div>
                    <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                        <p>Select Model:</p>
                        <Select value={this.state.vehicleModel ? this.state.vehicleModel : ''} onChange={this.updateVehicle} >
                            {this.state.vehicleModels.map(vehicleModel => {
                                return <MenuItem key={vehicleModel} value={vehicleModel}>{vehicleModel}</MenuItem>
                            })}
                        </Select>
                    </div>
                    {this.state.vehicleModel ?
                        <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                            <p>Select Model Year:</p>
                            <Select value={this.state.vehicleYear ? this.state.vehicleYear : ''} onChange={this.updateVehicleYear} >
                                {this.state.vehicleYears.map(vehicleYear => {
                                    return <MenuItem key={vehicleYear} value={vehicleYear}>{vehicleYear}</MenuItem>
                                })}
                            </Select>
                        </div>
                        : null
                    }
                    <div>
                        {(this.state.vehicleModel && this.state.vehicleYear) ?
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.state.spinnerOn}
                                onClick={this.promoteTemplate}
                            >{"Promote Template Data to " + this.destinationEnvironment}</Button>
                         : null}
                    </div>
                </div>
            )
        }
        else if (this.state.templateType === "Competitive Advantages" || this.state.templateType === "Model Overview (MY21+)") {
            return (

                <div>
                    <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                        <p>Select Model:</p>
                        <Select value={this.state.vehicleModel ? this.state.vehicleModel : ''} onChange={this.updateVehicle} >
                            {this.state.vehicleModels.map(vehicleModel => {
                                return <MenuItem key={vehicleModel} value={vehicleModel}>{vehicleModel}</MenuItem>
                            })}
                        </Select>                    </div>
                    {this.state.vehicleModel ?
                        <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                            <p>Select Model Year:</p>
                            <Select value={this.state.vehicleYear ? this.state.vehicleYear : ''} onChange={this.updateVehicleYear} >
                                {this.state.vehicleYears.map(vehicleYear => {
                                    return <MenuItem key={vehicleYear} value={vehicleYear}>{vehicleYear}</MenuItem>
                                })}
                            </Select>                        </div>
                        : null
                    }
                    <div>
                        {(this.state.vehicleModel && this.state.vehicleYear) ?
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.state.spinnerOn}
                                onClick={this.promoteTemplate}
                            >{"Promote Template Data to " + this.destinationEnvironment}</Button>
                         : null}
                    </div>
                </div>
            )
        }
        else if (this.state.templateType === "Edge (Legacy)" || this.state.templateType === "Head to Head" || this.state.templateType === "Head to Head v2") {
            return (

                <div>
                    <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                        <p>Select Model:</p>
                        <Select value={this.state.vehicleModel ? this.state.vehicleModel : ''} onChange={this.updateVehicle} >
                            {this.state.vehicleModels.map(vehicleModel => {
                                return <MenuItem key={vehicleModel} value={vehicleModel}>{vehicleModel}</MenuItem>
                            })}
                        </Select>
                    </div>
                    {this.state.vehicleModel ?
                        <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                            <p>Select Model Year:</p>
                            <Select value={this.state.vehicleYear ? this.state.vehicleYear : ''} onChange={this.updateVehicleYear} >
                                {this.state.vehicleYears.map(vehicleYear => {
                                    return <MenuItem key={vehicleYear} value={vehicleYear}>{vehicleYear}</MenuItem>
                                })}
                            </Select>
                        </div>
                        : null
                    }
                    {this.state.vehicleModel && this.state.vehicleYear && this.state.trimsLoaded ?
                        <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                            <p>Select Trim:</p>
                            <Select value={this.state.vehicleTrim ? this.state.vehicleTrim : ''} onChange={this.updateVehicleTrim} >
                                {this.state.vehicleTrimsDropdown.map(vehicleTrim => {
                                    return <MenuItem key={vehicleTrim} value={vehicleTrim}>{vehicleTrim}</MenuItem>
                                })}
                            </Select>
                        </div>
                        : null
                    }
                    {this.state.vehicleTrim ?
                        <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px" }}>
                            <p>Select Competitor:</p>
                            <Select value={this.state.vehicleCompetitor ? this.state.vehicleCompetitor : ''} onChange={this.updateCompetitor} >
                                {this.state.vehicleCompetitors.map(vehicleCompetitor => {
                                    return <MenuItem key={vehicleCompetitor} value={vehicleCompetitor}>{vehicleCompetitor}</MenuItem>
                                })}
                            </Select>
                        </div>
                        : null
                    }
                    {(this.state.vehicleTrim && this.state.vehicleCompetitor) ?
                        <div>
                            <br />
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.state.spinnerOn}
                                onClick={this.promoteTemplate}
                            >{"Promote Template Data to " + this.destinationEnvironment}</Button>
                        </div>
                        : null}
                </div>
            )
        }
        else { // default options for promoting templates
            return (
                <div>
                    <br />
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={this.state.spinnerOn}
                        onClick={this.promoteTemplate}
                    >{"Promote Template Data to " + this.destinationEnvironment}</Button>
                    <br />
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginTop: "20px" }}>
                    <p>Select Template Type:</p>
                    <Select value={this.state.templateType ? this.state.templateType : ''} onChange={this.updateTemplate} >
                        {this.state.templateTypes.map(templateType => {
                            return <MenuItem key={templateType} value={templateType}>{templateType}</MenuItem>
                        })}
                    </Select>
                </div>
                <this.TemplateBody />
                <NotificationDialog ref={dialogRef => (this.notificationDialog = dialogRef)} />
                <br />
                {this.state.spinnerOn ? <CircularProgress size={50} /> : null}
            </div>
        );
    }
}


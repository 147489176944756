import { Button, MenuItem, Select, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import React from 'react';
import { postFile } from '../../services/mcApiService';
import '../../App.css';
import UpcomingVehiclesSelection from './upcomingVehiclesMenu.js';
import FileUploader from '../../components/FileUploader';
import { getBase64, getEnvironments } from '../../utils';

export default class UpdateMenuIconComponent extends React.Component {

    constructor(props) {
        super(props);
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.state = {
            menuSections: [],
            menuItems: [],
            open: false
        };
        this.getMenuSections = this.getMenuSections.bind(this);
        this.getMenuItems = this.getMenuItems.bind(this);
        this.menuSectionSelection = this.menuSectionSelection.bind(this);
        this.menuItemSelection = this.menuItemSelection.bind(this);
        this.addFrontImage = this.addFrontImage.bind(this);
        this.addProfileImage = this.addProfileImage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleSuccessMessageClose = this.handleSuccessMessageClose.bind(this);
    }

    componentWillMount() {
        let menuSections = this.getMenuSections();
        this.setState({
            menuSections
        });
    }

    uploadImages = async function () {
        if (this.state.frontImage) {
            let result = await this.uploadImage("front", this.state.frontImage);
        }
        if (this.state.profileImage) {
            let result = await this.uploadImage("profile", this.state.profileImage);
        }
    }

    uploadImage(imagePath, file) {
        return new Promise(
            (resolve, reject) => {
                let path = "static/images/jellybeans/" + imagePath + "/";
                let fileName = this.state.menuItem.replace('&', '').replace(/\s/g, '_') + '.png';
                let fileType = file.type;

                getBase64(file)
                    .then(encodedData => {
                        let body = {
                            'encodedData': encodedData,
                            'fileName': fileName,
                            'fileType': fileType,
                            'path': path
                        }
                        postFile(body, 'Images')
                            .then(function (response) {
                                console.log("Uploaded image");
                                this.setState({
                                    open: false,
                                    uploadComplete: true,
                                    uploadSuccessMessage: "Successful Update"
                                })
                                return resolve(response)
                            }.bind(this))
                            .catch(function (err) {
                                console.log(err);
                                this.setState({
                                    uploadComplete: true,
                                    uploadSuccessMessage: "Error uploading images."
                                })
                                return reject(err);
                            }.bind(this));
                    })
            }
        );
    }

    menuSectionSelection(event) {
        this.setState({
            menuSection: event.target.value,
            menuItems: this.getMenuItems(event.target.value)
        });
    }

    menuItemSelection(event) {
        this.setState({
            menuItem: event.target.value
        });
    }

    getMenuSections() {
        return [
            "Cars & Minivans",
            "Crossover & SUVs",
            "Trucks",
            "Non-Production",
            "Upcoming Vehicles",
        ];
    }

    getMenuItems(section) {
        switch (section) {
            case "Cars & Minivans":
                return [
                    "86",
                    "Avalon",
                    "Camry",
                    "Corolla",
                    "GR Corolla",
                    "Corolla Hatchback",
                    "GR Supra",
                    "Mirai",
                    "Prius",
                    "Prius Prime",
                    "Sienna",
                    "Toyota Crown"
                ];
            case "Crossover & SUVs":
                return [
                    "4Runner",
                    "bZ4X",
                    "C-HR",
                    "Corolla Cross",
                    "Grand Highlander",
                    "Highlander",
                    "RAV4",
                    "RAV4 Prime",
                    "Sequoia",
                    "Venza",
                    "Toyota Crown Signia"
                ];
            case "Trucks":
                return [
                    "Tacoma",
                    "Tundra",
                ];
            case "Non-Production":
                return [
                    "Corolla iM",
                    "Prius c",
                    "Prius v",
                    "Prius Plug-In Hybrid",
                    "Yaris",
                    "Yaris Sedan",
                    "Yaris iA",
                    "Scion FR-S",
                    "Scion iA",
                    "Scion iM",
                    "Scion iQ",
                    "Scion tC",
                    "Scion xB",
                    "Scion xD",
                    "Land Cruiser"
                ];
            case "Upcoming Vehicles":
                return [];
            default:
                break;
        }
    }

    addFrontImage(event) {
        let file = event.target.files[event.target.files.length - 1];
        console.log('front')
        this.setState({
            frontImage: file
        });
    }

    addProfileImage(event) {
        let file = event.target.files[event.target.files.length - 1];
        console.log('profile')
        this.setState({
            profileImage: file
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSuccessMessageClose = () => {
        this.setState({ uploadComplete: false });
        window.location.reload();
    };

    render() {

        const actions1 = [
            <Button
                color='primary'
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                color='primary'
                keyboardFocused={true}
                onClick={this.uploadImages}
            >Upload</Button>
        ];

        const actions2 = [
            <Button
                color='primary'
                onClick={this.handleSuccessMessageClose}
            >Ok</Button>
        ];

        return (
            <>
                <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginTop: "30px" }}>
                    <p>Select Menu Section:</p>
                    <Select value={this.state.menuSection} onChange={this.menuSectionSelection} >
                        {this.state.menuSections.map(menuSection => {
                            return <MenuItem key={menuSection} value={menuSection}>{menuSection}</MenuItem>
                        })}
                    </Select>
                </div>
                {this.state.menuSection && (
                    this.state.menuSection !== 'Upcoming Vehicles' ?
                        <>
                            <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginTop: "30px" }}>
                                <p>Select Menu Item:</p>
                                <Select value={this.state.menuItem} onChange={this.menuItemSelection}>
                                    {this.state.menuItems.map(menuItem => (
                                        <MenuItem key={menuItem} value={menuItem}>
                                                {menuItem}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div style={{ marginTop: "40px" }}>
                                {this.state.menuSection && this.state.menuItem ? <FileUploader title="Upload Default/Profile Image" reuploadTitle="Upload Different Default/Profile Image" addFile={this.addProfileImage} /> : null}
                                {this.state.profileImage && 'Default/Profile Image Uploaded'}
                            </div>
                            <div style={{ marginTop: "40px" }}>
                                {this.state.menuSection && this.state.menuItem ? <FileUploader title="Upload Hover/Front Image" reuploadTitle="Upload Different Hover/Front Image" addFile={this.addFrontImage} /> : null}
                                {this.state.frontImage && 'Hover/Front Image Uploaded'}
                            </div>
                            <div style={{ marginTop: "40px" }}>
                                {(this.state.frontImage || this.state.profileImage) ? <Button variant='contained' color='secondary' onClick={() => { this.setState({ open: true }) }} >Publish To {this.sourceEnvironment}</Button> : null}
                            </div>
                        </>
                    :
                        <UpcomingVehiclesSelection />
                )}
                <Dialog
                    modal={false}
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                <DialogTitle>
                    {"Are you sure you want to submit these menu images to " + this.sourceEnvironment + "?"}
                </DialogTitle>
                <DialogActions>
                    {actions1}
                </DialogActions>
                </Dialog>
                <Dialog
                    modal={false}
                    open={this.state.uploadComplete}
                    onClose={this.handleSuccessMessageClose}
                >
                    <DialogTitle>
                        {this.state.uploadSuccessMessage}
                    </DialogTitle>
                    <DialogActions>
                        {actions2}
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

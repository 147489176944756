import React from "react";
import {
  Popover,
  Switch,
  Typography,
  Box,
} from "@material-ui/core";

const FilterPopover = ({
  anchorEl,
  handleClose,
  allFilterTypes,
  selectedFilterTypes,
  onToggle,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          padding: 2,
          width: 200,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {allFilterTypes?.map((filterType) => {
          const isChecked = selectedFilterTypes?.map(filterType => filterType.value).includes(filterType.value);

          return (
            <Box
              paddingX={1}
              key={filterType.value}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{filterType.label}</Typography>
              <Switch
                checked={isChecked}
                onChange={() => onToggle(filterType.value)}
              />
            </Box>
          );
        })}
      </Box>
    </Popover>
  );
};

export default FilterPopover;

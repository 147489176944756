import {
    Container,
} from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';

import '../../App.css';
import { FeedbackContext } from '../../components/FeedbackSnackbar';
import { getNotificationCategories, publishNotification } from '../../services/mcApiService';
import config from '../../config';
import NotificationForm from '../../components/NotificationForm/notificationForm';
import PreviewSection from '../../components/NotificationPreviews/previewsSection';

function CreateNotificationComponent() {
    const [categories, setCategories] = useState();
    const [notification, setNotification] = useState({
        category: '',
        title: '',
        message: '',
        redirectPath: '',
        isPush: false,
        icon: '',
        imageName: ''
    });
    const [loading, setLoading] = useState(false);
    const { snackbar, setSnackbar } = useContext(FeedbackContext);

    // Initialize category data
    useEffect(() => {
        if (!categories) {
            getNotificationCategories()
                .then(data => {
                    if (data) {
                        setCategories(data.categories);
                    } else {
                        console.log("Error getting notificaion categories");
                    }
                });
        }
    }, []);

    function createNewNotification(imageName) {
        const today = new Date();
        const newNotification = {
            ...notification,
            createdDate: today,
            lastModifiedDate: today,
        };
        imageName && (newNotification['imageName'] = imageName);
        setLoading(true);
        publishNotification(newNotification).then(() => {
            // store snackbar state for access after redirect
            window.history.pushState({ snackbar: { open: true, success: true, message: "Notification published successfully!" } }, '', '/notifications/activity');
            window.location.pathname = '/notifications/activity';
        })
            .catch((err) => {
                setLoading(false);
                setSnackbar({ open: true, success: false, messsage: "Notification failed to publish." });
            });
        console.log(`Publishing new notification to ${config.environments.formattedSourceEnvironment}...`);
        console.log(newNotification);
    }

    return <Container style={{ marginTop: '25px' }} >
        <NotificationForm
            categories={categories}
            notification={notification}
            setNotification={setNotification}
            save={createNewNotification}
            saveLabel={`Publish to ${config.environments.formattedSourceEnvironment}`}
            loading={loading}
        />

        <PreviewSection
            push={notification.isPush}
            announcement={notification.category !== 'Outage Alert'}
            outage={notification.category === 'Outage Alert'}
            title={notification.title || 'Your Title Here'}
            message={notification.message || 'Your Message Here'}
            selectedCategoryIcon={notification.icon}
            shouldRedirect={!!notification.redirectPath}
            redirectButtonLabel={"Learn More"}
        />
    </Container>;
}

export default CreateNotificationComponent;
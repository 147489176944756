import { useEffect, useState } from "react";

// Reusable components
import MetricBox from "../../../components/MetricBox";

// API Service
import { getArticlesMetadata, getUsersMetadata } from "../../../services/analyticsDashboardService";
import { getBoxesPeriodData, getActiveUsersPeriodData, setMetadataPeriodData, setMetadataUsersPeriodData } from "../utils";

const BoxesComponents = () => {
  const [videosUploadedData, setVideosUploadedData] = useState({
    Title: "Videos Uploaded",
    Metrics: [],
  });
  const [articlesPublishedData, setArticlesPublishedData] = useState({
    Title: "Articles Published",
    Metrics: [],
  });

  const [activeUsersData, setActiveUsersData] = useState({
    Title: "Active Users",
    Metrics: [],
  });

  useEffect(() => {
    loadArticlesData();
  }, []);

  const loadArticlesData = async () => {
    const [videoMetadata, articleMetada, userMetadata] = await Promise.allSettled([
      getArticlesMetadata({
        articleCategory: "Video",
      }),
      getArticlesMetadata({
        articleCategory: "Article",
      }),
      getUsersMetadata({}),
    ]);
    if (videoMetadata.status === "fulfilled") {
      const videoMetadataInfo = getBoxesPeriodData(videoMetadata.value);
      setMetadataPeriodData(videoMetadataInfo, setVideosUploadedData);
    }
    if (articleMetada.status === "fulfilled") {
      const articleMetadaInfo = getBoxesPeriodData(articleMetada.value);
      setMetadataPeriodData(articleMetadaInfo, setArticlesPublishedData);
    }
    if (userMetadata.status === "fulfilled") {
      const userMetadaInfo = getActiveUsersPeriodData(userMetadata.value);
      setMetadataUsersPeriodData(userMetadaInfo, setActiveUsersData);
    }
  };

  return (
    <>
      <MetricBox data={videosUploadedData} borderColor="#8884d8"/>
      <MetricBox data={articlesPublishedData} borderColor="#8884d8"/>
      <MetricBox data={activeUsersData} borderColor="#57C2D8"/>
    </>
  );
};

export default BoxesComponents;

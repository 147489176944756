import React, { useState } from 'react';
import { Modal, Backdrop, Fade, Box, Button } from '@material-ui/core';
import NotificationActivity from '../../NotificationActivity';

const NotificationSelectorModalWrapper = ({ children }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {/* Child component to trigger the modal */}
            {React.cloneElement(children, { onClick: handleOpen })}

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                className="notifications-modal"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className="notifications-modal-paper">
                        <NotificationActivity />
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default NotificationSelectorModalWrapper;